import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RadioButtonGroup } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import { designTokens } from '../theme/theme.tokens'
import { H2 } from './generic/TextStyledComponents'
import { Row } from './generic/layoutStyledComponents'
import { RowContainer } from './appMiscStyledComponents'
import IconButton from './generic/IconButton/IconButton'
// refoctor needed: this file should probably be renamed to
// 'ModalStyledcomponents' and have the form components broken
// into its own file, etc.

export const IndentedRadioButtonGroup = styled(RadioButtonGroup)`
  padding-left: ${designTokens.spacing.small};
`

export const SidePanelH2 = styled(H2)`
  margin: ${props => props.theme.designTokens.spacing.small};
`
export const SidePanelH3 = styled('h3')`
  font-size: ${props => props.theme.designTokens.typography.h4Size};
  font-weight: bold;
`
export const SidePanelTitle = styled(SidePanelH2)`
  & svg {
    margin-right: ${designTokens.spacing.small};
  }
`
export const ModalRowContainer = styled(RowContainer)`
  min-width: 750px;
  & > ${Row} {
    border-bottom: none;
  }
  & > :first-child {
    border-bottom: ${props => props.theme.designTokens.border.primary};
    padding-top: 0;
  }
`

const _IconButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    margin-top: ${props => props.theme.designTokens.spacing.xsmall};
    margin-right: ${props => props.theme.designTokens.spacing.xsmall};
  }
`

// intended to be top right X button, e.g. for side panel, and other modals
export const CloseButton = ({ onClose }) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <_IconButtonContainer>
    <IconButton
      data-testid="close button"
      ariaLabel="close"
      icon={<FontAwesomeIcon icon={faTimes} />}
      onClick={onClose}
    />
  </_IconButtonContainer>
)

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
}
