import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useReducer, useEffect } from 'react'

import {
  RowBottomButtonErrorRight,
  RowBottomButtons2Right1Left,
  RowContainer,
} from '../appMiscStyledComponents'

import {
  ButtonCaution,
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { AuthTokenColumn, AuthTokenRow } from './EditUser.styled'
import { Code } from '../generic/TextStyledComponents'
import { Column, Row, RowSpaceBetween } from '../generic/layoutStyledComponents'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Form, FormSectionLabel } from '../generic/formStyledComponents'
import { GroupCheckboxes } from '../NewUser/GroupCheckboxes'
import { reducer } from './EditUser.reducer'
import { SidePanelTitle } from '../SidePanelStyledComponents'
import { userServicePropType, groupServicePropType, authenticationServicePropType } from '../../dataServices'
import ConfirmAuthTokenReset from '../ConfirmAuthTokenReset/ConfirmAuthTokenReset'
import ConfirmDelete from '../ConfirmDelete'
import ResetPassword from '../ResetPassword'

/**
 * Edit User form
 */

const EditUser = ({ userService, userId, onCancel, onDelete, onSave, groupList, authenticationService }) => {
  const initialState = {
    hasApiReturned: false,
    role: '',
    groups: [],
    nad27Preferred: false,
    availableGroups: groupList,
    showDeleteConfirm: false,
    showResetTokenConfirm: false,
    deleteUserServerError: undefined,
    resetTokenServerError: undefined,
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()

  useEffect(() => {
    userService.getUser(userId).then(userInfo => {
      dispatch({ type: 'receivedUserInfo', payload: userInfo })
    })
  }, [userService, userId])

  const handleSave = formValues => {
    const modifiedUserInfo = {
      id: userId,
      role: formValues.role,
      active: formValues.active,
      nad_27_preferred: formValues.nad27Preferred,
      auth_token: state.auth_token
    }

    userService
      .editUser(modifiedUserInfo)
      .then(() => {
        const findGroupByName = name => state.availableGroups.find(group => group.name === name)
        const groupIds = formValues.groups.map(groupName => findGroupByName(groupName).id)

        return userService.updateGroupMembership(userId, groupIds)
      })
      .then(() => {
        onSave(state.name)
      })
      .then(() => {
        if (modifiedUserInfo.id === authenticationService.getUserFromStorage().userId) {
          authenticationService.updateUserProperty("authorization", modifiedUserInfo.role)
          history.push('/')
          window.location.reload()
        }
      })
      .catch(() => {
        dispatch({ type: 'editUserServerError' })
      })
  }
  const handleDelete = () => {
    dispatch({ type: 'showDeleteConfirm' })
  }
  const handleDeleteCancel = () => {
    dispatch({ type: 'cancelDelete' })
  }
  const handleDeleteConfirm = () => {
    userService
      .deleteUser(userId)
      .then(() => {
        onDelete({ id: userId, name: state.name })
      })
      .catch(() => {
        dispatch({ type: 'deleteUserServerError' })
      })
  }

  const handleResetToken = () => {
    dispatch({ type: 'showResetTokenConfirm' })
  }
  const handleResetTokenConfirm = () => {
    dispatch({
      type: 'resetResetTokenConfirm',
    })
  }
  const handleResetTokenCancel = () => {
    dispatch({
      type: 'resetResetTokenConfirm',
    })
  }

  const handleResetPassword = () => {
    dispatch({ type: 'showResetPassword' })
  }
  const handleResetPasswordCancel = () => {
    dispatch({ type: 'resetResetPassword' })
  }
  const handleResetPasswordConfirm = values => {
    userService.resetPassword(values, state.id)
      .then(() => {
        dispatch({ type: 'resetResetPassword' })
        toast.success(`${state.name}'s password has been reset`)
      })
      .catch(() => {
        dispatch({ type: 'resetPasswordError' })
      })
  }

  const formik = useFormik({
    initialValues: {
      role: state.role,
      groups: state.groups,
      nad27Preferred: state.nad27Preferred,
      active: state.active,
    },
    onSubmit: handleSave,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true, // so you can get initial values from state
  })

  return (
    <>
      {state.showDeleteConfirm && (
        <ConfirmDelete
          deleteObjectName={`the account, '${state.name}'`}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          notModal
          errorMessage={state.deleteUserServerError}
        />
      )}
      {state.showResetTokenConfirm && (
        <ConfirmAuthTokenReset
          onCancel={handleResetTokenCancel}
          onConfirm={handleResetTokenConfirm}
          userService={userService}
          userId={userId}
        />
      )}
      {state.showResetPassword && (
        <ResetPassword
          onCancel={handleResetPasswordCancel}
          onConfirm={handleResetPasswordConfirm}
          errorMessage={state.resetPasswordError}
        />
      )}
      {!state.showDeleteConfirm && !state.showResetTokenConfirm && !state.showResetPassword && (
        <Form onSubmit={formik.handleSubmit}>
          <RowContainer>
            <RowSpaceBetween>
              <SidePanelTitle>
                <FontAwesomeIcon icon={faUserCircle} />
                {state.name}
              </SidePanelTitle>
              <ButtonTertiary type="button" onClick={handleResetPassword}>Reset Password</ButtonTertiary>
            </RowSpaceBetween>
            <Row>
              <Column>
                <FormSectionLabel>Role</FormSectionLabel>
                <label htmlFor="admin">
                  <input
                    type="radio"
                    id="admin"
                    name="role"
                    value="admin"
                    checked={formik.values.role === 'admin'}
                    onChange={() => {
                      formik.setFieldValue('role', 'admin')
                    }}
                  />
                  Admin
                </label>
                <label htmlFor="general">
                  <input
                    type="radio"
                    id="general"
                    name="role"
                    value="general"
                    checked={formik.values.role === 'general'}
                    onChange={() => {
                      formik.setFieldValue('role', 'general')
                    }}
                  />
                  General User
                </label>
                <label htmlFor="server">
                  <input
                    type="radio"
                    id="server"
                    name="role"
                    value="server"
                    checked={formik.values.role === 'server'}
                    onChange={() => {
                      formik.setFieldValue('role', 'server')
                    }}
                  />
                  Server
                </label>
              </Column>
            </Row>
            <Row>
              <Column>
                <label htmlFor="nad27Preferred">
                  <input
                    type="checkbox"
                    id="nad27Preferred"
                    name="nad27Preferred"
                    value="nad27Preferred"
                    checked={formik.values.nad27Preferred}
                    onChange={formik.handleChange}
                  />
                  Use NAD27 for WMS
                </label>
              </Column>
            </Row>
            <Row>
              <Column>
                <GroupCheckboxes state={state} formik={formik} />
              </Column>
            </Row>
            <Row>
              <AuthTokenColumn>
                <FormSectionLabel>Auth Token</FormSectionLabel>
                <AuthTokenRow>
                  <Code>{state.authToken}</Code>
                  <ButtonTertiary type="button" onClick={handleResetToken}>
                    Reset Token
                  </ButtonTertiary>
                </AuthTokenRow>
              </AuthTokenColumn>
            </Row>
             <Row>
              <Column>
                <label htmlFor="active">
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    value="active"
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                  />
                  User is active
                </label>
              </Column>
            </Row>
          </RowContainer>
          <RowBottomButtons2Right1Left>
            <ButtonCaution type="button" onClick={handleDelete}>
              Delete
            </ButtonCaution>
            <div>
              <ButtonTertiary type="button" onClick={onCancel}>
                Cancel
              </ButtonTertiary>
              <ButtonPrimary type="submit" isError={state.editUserServerError}>
                Save User
              </ButtonPrimary>
            </div>
          </RowBottomButtons2Right1Left>
          {state.editUserServerError && (
            <RowBottomButtonErrorRight>
              <ErrorMessage>{state.editUserServerError}</ErrorMessage>
            </RowBottomButtonErrorRight>
          )}
        </Form>
      )}
    </>
  )
}

EditUser.propTypes = {
  userService: userServicePropType.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  groupList: PropTypes.arrayOf(groupServicePropType).isRequired,
  authenticationService: authenticationServicePropType.isRequired
}

export default EditUser
