import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { designTokens } from '../../theme/theme.tokens'
import PasswordInput from '../generic/PasswordInput'
import { Form } from '../generic/formStyledComponents'
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../generic/buttonStyledComponents'
import { validateRegex, validationMessage } from '../../helpers/passwordRequirements'

const ContentsContainer = styled('div')`
  min-width: 500px;
  display: flex;
  flex-direction: column;
  padding: ${designTokens.spacing.large};
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  margin-top: ${designTokens.spacing.large};
  & ${ButtonPrimary} {
    margin-left: ${designTokens.spacing.large};
  }
`

const ResetPassword = ({ onCancel, onConfirm, errorMessage }) => {

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      passwordConfirmation: '',
      currentPassword: ''
    },
    onSubmit: onConfirm,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Please enter the user\'s new password.')
        .matches(
          validateRegex,
          validationMessage
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
      currentPassword: Yup.string()
        .required('Please enter your current password')
    }),
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <ContentsContainer>
        <Row>
          <label htmlFor="newPassword">
            New Password
            <PasswordInput
              id="newPassword"
              errorMessage={formik.errors.newPassword}
              {...formik.getFieldProps('newPassword')}
            />
          </label>
        </Row>
        <Row>
          <label htmlFor="passwordConfirmation">
            Confirm New Password
            <PasswordInput
              id="passwordConfirmation"
              errorMessage={formik.errors.passwordConfirmation}
              {...formik.getFieldProps('passwordConfirmation')}
            />
          </label>
        </Row>
        <Row>
          <label htmlFor="currentPassword">
            Your Current Password
            <PasswordInput
              id="currentPassword"
              errorMessage={errorMessage ?? formik.errors.currentPassword}
              {...formik.getFieldProps('currentPassword')}
            />
          </label>
        </Row>
        <ButtonRow>
          <ButtonSecondary type="button" onClick={onCancel}>Cancel</ButtonSecondary>
          <ButtonPrimary type="submit">Reset Password</ButtonPrimary>
        </ButtonRow>
      </ContentsContainer>
    </Form>
  )
}

ResetPassword.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
}

ResetPassword.defaultProps = {
  errorMessage: undefined
}

export default ResetPassword
