import PropTypes from 'prop-types'
import { getServerBaseUrl, sortByNameCaseInsensitive } from '../helpers'

function checkIntValue(value) {
  return Number.isNaN(value) || value === '' ? null : parseInt(value, 10)
}

class OrganizationService {
  constructor(authenticationService) {
    this.axiosWithAuth = authenticationService.getAxiosInstanceWithAuthHeaders()
  }

  getOrganizations = () => {
    return this.axiosWithAuth
      .get(`${getServerBaseUrl()}organization/`)
      .then(response => {
        return sortByNameCaseInsensitive(response.data.data)
      })
  }

  getOrganization = id => {
    if (!id) {
      return new Promise(resolve => {
        resolve(undefined)
      })
    }

    return this.axiosWithAuth
      .get(`${getServerBaseUrl()}organization/${id}`)
      .then(response => {
        return response.data
      })
  }

  newOrganization = newOrganization => {
    return this.axiosWithAuth
      .post(`${getServerBaseUrl()}organization/`, {
        name: newOrganization.name,
      })
      .then(response => {
        return response.data
      })
  }

  editOrganization = updatedOrganization => {
    const {
      name,
      maphit_quota,
      hosted_data_quota,
      hosted_data_size,
      id,
    } = updatedOrganization

    return this.axiosWithAuth
      .put(`${getServerBaseUrl()}organization/${id}`, {
        name,
        maphit_quota: checkIntValue(maphit_quota),
        hosted_data_quota: checkIntValue(hosted_data_quota),
        hosted_data_size,
      })
      .then(response => {
        return response.data
      })
  }

  updateLayersForOrganization = (organization, layerIds) => {
    return this.axiosWithAuth
      .put(
        `${getServerBaseUrl()}organization/layers/${organization.id}`,
        layerIds,
      )
      .then(response => response.data)
  }
}

const OrganizationServicePropType = PropTypes.shape({
  getOrganizations: PropTypes.func,
  getOrganization: PropTypes.func,
  newOrganization: PropTypes.func,
  editOrganization: PropTypes.func,
  updateLayersForOrganization: PropTypes.func,
})
const organizationPropType = PropTypes.shape({
  admin: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  users: PropTypes.number,
})

export default OrganizationService
export { OrganizationServicePropType, organizationPropType }
