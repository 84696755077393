import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  SearchForm,
  InputContainer,
  SearchInput,
  ResetButton,
} from './FilterBar.styled'

/**
 * A search input, with magnifying glass icon, and 'Search' button
 */

const FilterBar = ({ onFilter, onReset }) => {
  const [showReset, setShowReset] = useState(false)

  return (
    <Formik
      initialValues={{ searchQuery: '' }}
      enableReinitialize={false}
      onReset={() => {
        setShowReset(false)
        onReset()
      }}
      validate={({ searchQuery }) => {
        setShowReset(!!searchQuery.trim().length)
      }}
    >
      {formikProps => {
        const handleFilterChange = event => {
          formikProps.handleChange(event)
          onFilter(event.target.value)
        }

        return (
          <SearchForm
            onSubmit={formikProps.handleSubmit}
            data-testid="filter-bar"
          >
            <label htmlFor="searchQuery">
              Filter all columns
              <InputContainer>
                <SearchInput
                  name="searchQuery"
                  id="searchQuery"
                  aria-label="filter query"
                  onChange={handleFilterChange}
                  value={formikProps.values.searchQuery}
                />
                {showReset && (
                  // eslint-disable-next-line react/button-has-type
                  <ResetButton
                    onClick={formikProps.handleReset}
                    type="reset"
                    aria-label="Reset Filter Query"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </ResetButton>
                )}
              </InputContainer>
            </label>
          </SearchForm>
        )
      }}
    </Formik>
  )
}

FilterBar.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default FilterBar
