import { faCog, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSortBy, useTable } from 'react-table'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import {
  getReactTableHeaders,
  Table,
  Td,
  Tr,
} from '../../generic/TableAndPagination'
import {
  RowForTableFilterAndButtons,
  StuffAboveTableContainer,
} from '../../appMiscStyledComponents'
import { authorizationLevelPropType } from '../../../dataServices/authenticationService'
import { ButtonPrimary } from '../../generic/buttonStyledComponents'
import { groupServicePropType } from '../../../dataServices'
import { H1 } from '../../generic/TextStyledComponents'
import { organizationPropType } from '../../../dataServices/organizationService'
import { useFilterableTableDataAndState } from '../../../helpers'
import FilterBar from '../../generic/FilterBar'
import IconButton from '../../generic/IconButton'
import NoFilterResults from '../../NoFilterResults'
import NoItemsFromApi from '../../NoItemsFromApi'
import StatusIndicator from '../../generic/StatusIndicator'

/**
 * Lists groups a user has access to in table. If superuser or admin, a create button shows
 */

const Groups = ({
  authorizationLevel,
  currentOrganization,
  groupService,
  onCreateGroupClick,
  onEditGroup,
  shouldRerender,
}) => {
  const isAuthorized =
    authorizationLevel === 'admin' || authorizationLevel === 'superuser'

  const getGroups = useCallback(
    () => groupService.getGroups(currentOrganization),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrganization, groupService, shouldRerender],
  )

  const {
    doesApiReturnData,
    hasApiReturned,
    tableData: filterableTableData,
    unfilteredTableData,
    setTableData,
  } = useFilterableTableDataAndState(getGroups)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Accounts',
        accessor: 'accounts',
        align: 'right',
      },
      {
        Header: 'Layers',
        accessor: 'layers',
        align: 'right',
      },
    ],
    [],
  )

  const {
    headerGroups,
    rows,
    getTableBodyProps,
    getTableProps,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filterableTableData,
    },
    useSortBy,
    false,
  )

  const resetFilter = () => {
    setTableData(unfilteredTableData.current)
  }

  const handleFilterInput = filterQuery => {
    const lowerCaseQuery = filterQuery.toLowerCase()
    const filterFunction = dataItem => {
      return (
        dataItem.name.toLowerCase().includes(lowerCaseQuery) ||
        dataItem.accounts.toString().toLowerCase().includes(lowerCaseQuery) ||
        dataItem.layers.toString().toLowerCase().includes(lowerCaseQuery)
      )
    }
    const filteredData = unfilteredTableData.current.filter(filterFunction)

    setTableData(filteredData)
  }

  const NoUsers = () =>
    doesApiReturnData ? (
      <NoFilterResults />
    ) : (
      <NoItemsFromApi
        itemType="group"
        onAddItem={onCreateGroupClick}
        showButton={isAuthorized}
      />
    )

  const FilterableTable = () =>
    filterableTableData.length ? (
      <Table {...getTableProps()}>
        <thead>
          {getReactTableHeaders(headerGroups, {
            showExtraHeaderForButtonsSpacing: isAuthorized,
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)

            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()} align={cell.column.align}>
                    {cell.render('Cell')}
                  </Td>
                ))}
                {isAuthorized && (
                  <Td>
                    <IconButton
                      icon={<FontAwesomeIcon icon={faCog} />}
                      tooltip="Modify"
                      ariaLabel="Modify"
                      onClick={() => {
                        onEditGroup(row.original)
                      }}
                    />
                  </Td>
                )}
              </Tr>
            )
          })}
        </tbody>
      </Table>
    ) : (
      <NoUsers />
    )

  return (
    <>
      <H1 data-testid="page-title">Groups</H1>

      {hasApiReturned && isAuthorized ? (
        <>
          <StuffAboveTableContainer>
            <RowForTableFilterAndButtons>
              {doesApiReturnData && (
                <FilterBar onFilter={handleFilterInput} onReset={resetFilter} />
              )}
              {isAuthorized && doesApiReturnData && (
                <ButtonPrimary onClick={onCreateGroupClick}>
                  <FontAwesomeIcon icon={faUserPlus} />
                  Create Group
                </ButtonPrimary>
              )}
            </RowForTableFilterAndButtons>
          </StuffAboveTableContainer>

          <FilterableTable />
        </>
      ) : (
        <StatusIndicator />
      )}
    </>
  )
}

Groups.propTypes = {
  authorizationLevel: authorizationLevelPropType.isRequired,
  currentOrganization: organizationPropType.isRequired,
  groupService: groupServicePropType.isRequired,
  onCreateGroupClick: PropTypes.func.isRequired,
  onEditGroup: PropTypes.func.isRequired,
  shouldRerender: PropTypes.number.isRequired,
}

export default Groups
