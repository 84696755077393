import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import React, { useState } from 'react'
import { organizationPropType, OrganizationServicePropType } from '../../dataServices/organizationService'
import { useFilterableTableDataAndState } from '../../helpers'
import { Table, Td, Th, Tr } from '../generic/TableAndPagination'
import { Form } from '../generic/formStyledComponents'
import { Row } from '../generic/layoutStyledComponents'
import NoFilterResults from '../NoFilterResults'
import FilterBar from '../generic/FilterBar'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import {
  NoDataContainer,
  StyledRowSpaceBetween,
} from './OrganizationManager.styled'
import {
  RowBottomButtons,
} from '../appMiscStyledComponents'
import NoItemsFromApi from '../NoItemsFromApi'
import StatusIndicator from '../generic/StatusIndicator/StatusIndicator'
import { dataServicePropType } from '../../dataServices'

const Title = styled('h4')`
  padding: 0 ${props => props.theme.designTokens.spacing.xlarge} 0;
  margin: ${props => props.theme.designTokens.spacing.medium} 0 0 0;
`

const getDataQuery = (layerService, organization) => {
  return () => {
    return Promise.all([
      layerService.getAllLayers(),
      layerService.getOrganizationLayers(organization, false)
    ]).then(([allLayers, organizationLayers]) => {
      const availableIds = organizationLayers.map(availableLayer => {
        return availableLayer.id
      })

      return allLayers.map(layer => {
        return {
          id: layer.id,
          name: layer.name,
          description: layer.description,
          title: layer.title,
          checked: availableIds.indexOf(layer.id) > -1,
          visible: true
        }
      }).sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      })
    })
  }
}

const ManageOrgLayersView = ({
  organization,
  organizationService,
  layerService,
  onClose
}) => {
  const [layerFilterTimeout, setLayerFilterTimeout] = useState(null)
  const [dataQuery] = useState(() => getDataQuery(layerService, organization))
  const tableDataAndState = useFilterableTableDataAndState(dataQuery)
  const {
    tableData,
    hasApiReturned,
    doesApiReturnData,
    unfilteredTableData,
    setTableData,
  } = tableDataAndState
  const handleSave = formValues => {
    organizationService.updateLayersForOrganization(
      organization,
      formValues.layers.map(strId => parseInt(strId, 10))
    ).then(() => {
      onClose()
      toast.success(`${organization.name} layers have been modified`)
    })
  }

  const formik = useFormik({
    initialValues: {
      layers: tableData
        .filter(layer => layer.checked)
        .map(layer => layer.id.toString())
    },
    onSubmit: handleSave,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true, // so you can get initial values from state
  })

  const resetFilter = () => {
    setTableData(unfilteredTableData.current)
  }

  const layerFilterTimeoutDelay = 200
  const layerFilter = filterQuery => {
    if (layerFilterTimeout) {
      window.clearTimeout(layerFilterTimeout)
      setLayerFilterTimeout(null)
    }
    // Layer list can be large - e.g. ~1,000 rows. Debounce filtering to avoid excessive unnecessary processing
    setLayerFilterTimeout(
      window.setTimeout(() => {
        const lowerCaseQuery = filterQuery.toLowerCase()
        const filterFunction = layer => {
          return (
            layer.title?.toLowerCase().includes(lowerCaseQuery) ||
            layer.name.toLowerCase().includes(lowerCaseQuery) ||
            layer.description?.toLowerCase().includes(lowerCaseQuery)
          )
        }
        /*
        Precedent from other components is to filter unmatched rows out of the list, however this will
        result in any checked layers being removed from formik.values.layers if they are filtered out.
        Usability is improved if layers remain checked when hidden, a users can incrementally build
        the full set of layers by filtering, checking, and re-filtering.
        No significant changes in performance were observed switching from filtering layers to setting
        display: none;
        */
        // eslint-disable-next-line prefer-object-spread
        const filteredLayers = unfilteredTableData.current.map(layer => Object.assign({}, layer, {
          visible: filterFunction(layer)
        }))

        setTableData(filteredLayers)
      }, layerFilterTimeoutDelay)
    )
  }

  const NoData = () => {
    if (doesApiReturnData) {
      return <NoFilterResults />
    }
    if (hasApiReturned) {
      return <NoItemsFromApi itemType="layer" showButton={false} onAddItem={() => {}} />
    }

    return <StatusIndicator />
  }

  const FilterableTable = () =>
    tableData.length ? (
      <Row>
        <Table>
          <thead>
            <Tr>
              <Th />
              <Th>Name</Th>
              <Th>Title</Th>
              <Th>Description</Th>
            </Tr>
          </thead>
          <tbody>
            {tableData.map(layer => {
              return (
                <Tr
                  key={layer.id}
                  style={layer.visible ? {} : { display: 'none' }}>
                  <Td>
                  <input
                    type="checkbox"
                    id={layer.id}
                    name="layers"
                    value={layer.id}
                    defaultChecked={formik.values.layers.includes(layer.id.toString())}
                    onChange={formik.handleChange}
                    />
                  </Td>
                  <Td>{layer.name}</Td>
                  <Td>{layer.title}</Td>
                  <Td>{layer.description}</Td>
                </Tr>
              )
            })}
          </tbody>
        </Table>
      </Row>
    ) : (
      <NoDataContainer>
        <NoData />
      </NoDataContainer>
    )

  return (
    <>
      <Title>Manage Layers for {organization.name}</Title>
      {!!unfilteredTableData.current.length && (
        <StyledRowSpaceBetween>
          <FilterBar onReset={resetFilter} onFilter={layerFilter} />
        </StyledRowSpaceBetween>
      )}
      <Form onSubmit={formik.handleSubmit}>
        <FilterableTable />
        <RowBottomButtons>
          <ButtonTertiary onClick={onClose}>Cancel</ButtonTertiary>
          <ButtonPrimary type="submit">
            Update Organization
          </ButtonPrimary>
        </RowBottomButtons>
      </Form>
    </>
  )
}

ManageOrgLayersView.propTypes = {
  organization: organizationPropType.isRequired,
  organizationService: OrganizationServicePropType.isRequired,
  layerService: dataServicePropType.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ManageOrgLayersView
