import { designTokens } from './theme.tokens'

/* These design tokens are separate from 'theme design tokens',
which are a prototype for an eventual reusable design system repo
which would probably be imported and overriden separately from app tokens
*/

const appTokens = {
  appMinHeight: '500px',
  appMinWidth: '960px',
  brandTextDarkUrl: 'brand-text-dark.svg',
  brandTextLightUrl: 'brand-text-light.svg',
  loginPanelBackgroundColor: '#000000',
  logoHeaderHeight: '30px',
  logoHeaderWidth: '145px',
  logoLoginHeight: '72px',
  logoLoginWidth: '350px',
  logoUrl: 'logo.svg',
  navAnchorTextTransform: 'uppercase',
  navBackgroundColor: '#4C4B48',
  navBackGroundColorDarker: '#333231',
  navBorderActive: `solid ${designTokens.spacing.small} ${designTokens.color.brandPrimary}`,
  navWidth: '200px',
  sidePanelContentsMinWidth: '450px',
}

export default appTokens
