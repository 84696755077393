import PropTypes from 'prop-types'
import { getServerBaseUrl, sortByNameCaseInsensitive } from '../helpers'

class DataService {
  constructor(authenticationService) {
    this.axiosWithAuth = authenticationService.getAxiosInstanceWithAuthHeaders()
    this.gridAndSourceTypesPromise = this.#getGridAndSourceTypes()
  }

  getAllLayers = async () => {
    return this.axiosWithAuth
      .get(`${getServerBaseUrl()}layer/?with_groups=false`)
      .then(response => {
        return sortByNameCaseInsensitive(response.data.data)
      })
  }

  getOrganizationLayers = async (organization, withGroups) => {
    if (!organization.id) {
      // save a round trip to the server if a 'current organization' hasnt been initialized yet
      return new Promise((_resolve, reject) =>
        reject(new Error('No organization selected')),
      )
    }

    return this.axiosWithAuth
      .get(
        `${getServerBaseUrl()}layer/organization/${
          organization.id
        }?with_groups=${withGroups}`,
      )
      .then(response => {
        return sortByNameCaseInsensitive(response.data.data)
      })
  }

  getLayer = layerId => {
    return this.axiosWithAuth
      .get(`${getServerBaseUrl()}layer/config/${layerId}`)
      .then(response => {
        const {
          name,
          title,
          description,
          source_type_id, // eslint-disable-line camelcase
          source_url, // eslint-disable-line camelcase
          source_layer, // eslint-disable-line camelcase
          wms,
          wmts,
          wms_grid_id, // eslint-disable-line camelcase
          source_grid_id, // eslint-disable-line camelcase
          backfill,
          transparent,
          source_extra, // eslint-disable-line camelcase
          coverage,
          disable_cache, // eslint-disable-line camelcase
        } = response.data

        const layer = {
          name,
          humanName: title ?? '',
          description: description ?? '',
          type: source_type_id,
          source: source_url ?? '', // eslint-disable-line camelcase
          layer: source_layer ?? '', // eslint-disable-line camelcase
          wms,
          wmts,
          wmsGrid: wms_grid_id ?? '', // eslint-disable-line camelcase
          sourceGrid: source_grid_id ?? '', // eslint-disable-line camelcase
          backfill,
          transparent,
          sourceExtra: source_extra ?? '', // eslint-disable-line camelcase
          geom: coverage ?? '',
          disableCache: disable_cache,
        }

        return layer
      })
  }

  updateLayer = (layerId, updatedLayer) => {
    return this.axiosWithAuth.put(
      `${getServerBaseUrl()}layer/config/${layerId}`,
      updatedLayer,
    )
  }

  createLayer = newLayer => {
    return this.axiosWithAuth.post(
      `${getServerBaseUrl()}layer/config/`,
      newLayer,
    )
  }

  #getGridAndSourceTypes = () => {
    return Promise.all([this.getSourceGridTypes(), this.getSourceTypes()]).then(
      ([gridTypes, sourceTypes]) => {
        return {
          gridTypes: gridTypes.data,
          sourceTypes: sourceTypes.data,
        }
      },
    )
  }

  getSourceGridTypes = () => {
    return this.axiosWithAuth.get(`${getServerBaseUrl()}lookup/grid`)
  }

  getSourceTypes = () => {
    return this.axiosWithAuth.get(`${getServerBaseUrl()}lookup/source_type`)
  }

  decryptSource = encryptedString => {
    return this.axiosWithAuth
      .post(
        `${getServerBaseUrl()}secret/decrypt?data=${encodeURIComponent(
          encryptedString,
        )}`,
      )
      .then(response => response.data)
  }

  encryptSource = decryptedString => {
    return this.axiosWithAuth
      .post(
        `${getServerBaseUrl()}secret/encrypt?data=${encodeURIComponent(
          decryptedString,
        )}`,
      )
      .then(response => response.data)
  }

  getLayerConfigStatus = () => {
    return this.axiosWithAuth
      .get(`${getServerBaseUrl()}layer/config/status`)
      .then(response => response.data)
  }

  approveLayerConfigUpdate = () => {
    return this.axiosWithAuth.post(`${getServerBaseUrl()}layer/config/approve`)
  }
}

const dataServicePropType = PropTypes.shape({
  getOrganizationLayers: PropTypes.func,
  getAllLayers: PropTypes.func,
  getLayer: PropTypes.func,
  updateLayer: PropTypes.func.isRequired,
  createLayer: PropTypes.func.isRequired,
  decryptSource: PropTypes.func.isRequired,
  encryptSource: PropTypes.func.isRequired,
  getLayerConfigStatus: PropTypes.func.isRequired,
  approveLayerConfigUpdate: PropTypes.func.isRequired,
  gridAndSourceTypesPromise: PropTypes.shape({
    then: PropTypes.func.isRequired,
    catch: PropTypes.func.isRequired,
  }),
})

export default DataService
export { dataServicePropType }
