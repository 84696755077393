/*
    Password requirements:
    - 12 characters or more
    - At least one lower case
    - At least one upper case
    - At least one number
    - At least one special character (wide range permitted)

    This matches the API's schema validation rules. Keep in-sync if updating
*/
const validateRegex = /(?=.*\d+)(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{12,}/
const validationMessage =
  'Passwords must contain 12 or more characters and include at least one lower case letter, upper case letter, number, and special character'

export { validateRegex, validationMessage }
