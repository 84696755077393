import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { userServicePropType } from '../../dataServices'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import {
  Column,
  RowCenter,
  RowSpaceBetween,
} from '../generic/layoutStyledComponents'
import {
  ButtonCaution,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { P } from '../generic/TextStyledComponents'
import { RowBottomButtonErrorRight } from '../appMiscStyledComponents'

/**
 * Confirm prompt for resetting an auth token
 */
const Text = styled.div`
  margin-left: ${props => props.theme.designTokens.spacing.medium};
`

const CustomColumn = styled(Column)`
  max-width: 550px; /* this is just eyeballed depending on text content */
  justify-content: center;
`

const CustomRowSpaceBetween = styled(RowSpaceBetween)`
  align-items: flex-start;
`

const CustomRowCenter = styled(RowCenter)`
  padding-top: 0;
  padding-bottom: 0;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`
const ConfirmAuthTokenReset = ({ userService, onCancel, onConfirm, userId }) => {
  const [serverErrorMessage, setServerErrorMessage] = useState()
  const [buttonDisabled, setSubmitDisabled] = useState(true)

  const resetSelf = userId === undefined

  const toggleButtonDisabled = () => {
    setSubmitDisabled(!buttonDisabled)
  }

  const handleReset = () => {
    const requestor = resetSelf ? userService.resetOwnAuthToken() : userService.resetAuthToken(userId)

    requestor
      .then(() => {
        toast.success(`${resetSelf ? "Your" : "User's"} auth token has been reset`)
        setServerErrorMessage(undefined)
        onConfirm()
      })
      .catch(() => {
        setServerErrorMessage('Unable to reset token. Please try again.')
      })
  }

  return (
    <StyledContainer>
      <CustomColumn>
        <CustomRowSpaceBetween>
          <FontAwesomeIcon icon={faSyncAlt} size="2x" />
          <Text>
            <P>Are you sure you want to reset {resetSelf ? 'your' : 'this user\'s'} auth token? </P>
            <P>
              A token reset will remove access for any people or applications
              which are accessing the Spatial on Demand service. The old token
              cannot be retrieved once it has been reset.
            </P>
            {resetSelf && <P>You will be required to log back in.</P>}
          </Text>
        </CustomRowSpaceBetween>
        <CustomRowCenter>
          <label htmlFor="revoke">
            <input
              id="revoke"
              name="revoke"
              type="checkbox"
              onChange={toggleButtonDisabled}
            />
            Revoke access to all applications using this auth token
          </label>
        </CustomRowCenter>

        <RowSpaceBetween>
          <ButtonTertiary onClick={onCancel}>Cancel</ButtonTertiary>
          <ButtonCaution
            onClick={handleReset}
            isError={!!serverErrorMessage}
            disabled={buttonDisabled}
          >
            Reset Token
          </ButtonCaution>
        </RowSpaceBetween>
        {serverErrorMessage && (
          <RowBottomButtonErrorRight>
            <ErrorMessage>{serverErrorMessage}</ErrorMessage>
          </RowBottomButtonErrorRight>
        )}
      </CustomColumn>
    </StyledContainer>
  )
}

ConfirmAuthTokenReset.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userService: userServicePropType.isRequired,
  userId: PropTypes.number
}
ConfirmAuthTokenReset.defaultProps = {
  userId: undefined
}

export default ConfirmAuthTokenReset
