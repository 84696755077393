import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import { designTokens } from '../../theme'
import IconButton from '../generic/IconButton'
/**
 * A reusable component to display currently selected group or organization (or some future thing), with a button to manage groups or organizations
 */
const spacingTokens = designTokens.spacing
const padding = spacingTokens.small
const StyledContainer = styled.div`
  color: ${designTokens.color.textLight};
  background-color: ${designTokens.app.navBackgroundColor};
  padding: ${padding};
  font-size: ${designTokens.typography.h3Size};
  display: flex;
  justify-content: space-between;
`
const textContentRightMargin = spacingTokens.xsmall
const TextContent = styled.div`
  margin-right: ${textContentRightMargin};
  overflow-wrap: break-word;
  max-width: calc(
    ${designTokens.app.navWidth} - ${textContentRightMargin} -
      calc(${padding}* 2)
  );
`
const TextContentWithButtonRoom = styled(TextContent)`
  max-width: calc(
    ${designTokens.app.navWidth} - ${textContentRightMargin} -
      calc(${padding}* 2) - ${designTokens.button.buttonIconWidth}
  );
`

const StyledIconButton = styled(IconButton)`
  color: ${designTokens.color.textLight};
`

const NavInfoDisplay = ({ info, onClick, className, tooltip, showButton }) => (
  <StyledContainer className={className}>
    {showButton ? (
      <>
        <TextContentWithButtonRoom>{info}</TextContentWithButtonRoom>
        <div>
          <StyledIconButton
            tooltip={tooltip}
            ariaLabel={tooltip}
            type="button"
            onClick={onClick}
            icon={<FontAwesomeIcon icon={faCog} />}
          />
        </div>
      </>
    ) : (
      <TextContent>{info}</TextContent>
    )}
  </StyledContainer>
)

NavInfoDisplay.propTypes = {
  className: PropTypes.string,
  info: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
}

NavInfoDisplay.defaultProps = {
  className: '',
  onClick: undefined,
  showButton: false,
}

export default NavInfoDisplay
