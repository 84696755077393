import { Link } from 'react-router-dom'
import color from 'color'
import styled from 'styled-components/macro'

import { $TextInputContainer } from '../generic/TextInput/TextInput'

import {
  BoilerPlateButton,
  $RedBorderButton,
} from '../generic/ButtonError/ButtonError'
import PasswordInput from '../generic/PasswordInput'
import TextInput from '../generic/TextInput'
import { getBaseUrl, getRandomInt } from '../../helpers'

export const images = [
  'Cushing_30cm_Feb2018_WV3_1_web.jpg',
  'Houston_30cm_Jan2017_WV3_1_web.jpg',
  'Houston_30cm_Sep2017_WV3_1_web.jpg',
  'Permian_30cm_May2017_WV3_1_web.jpg',
]

export const randomImageFilePath = `${getBaseUrl()}/loginPageImages/${
  images[getRandomInt(images.length)]
}`
export const Page = styled('div')`
  height: 100%;
  width: 100%;
  min-height: 700px;
  background-image: url(${randomImageFilePath});
  background-size: cover;
  background-repeat: no-repeat;
`
export const LeftPanel = styled('div')`
  background-color: ${props =>
    color(props.theme.designTokens.app.loginPanelBackgroundColor)
      .fade(0.4)
      .toString()};
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  color: ${props => props.theme.designTokens.color.textLight};
  & > * {
    width: 100%;
  }
`

export const MainPanelSection = styled('div')`
  padding: ${props => props.theme.designTokens.spacing.large};
  box-sizing: border-box;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Logo = styled('div')`
  width: ${props => props.theme.designTokens.app.logoLoginWidth};
  height: ${props => props.theme.designTokens.app.logoLoginHeight};
  margin: 0;
`
export const BrandText = styled('div')`
  margin: ${props => props.theme.designTokens.spacing.xlarge} 0;
  width: ${props => props.theme.designTokens.app.logoLoginWidth};
`

export const SplashTitle = styled('h1')`
  color: ${props => props.theme.designTokens.color.brandPrimary};
  text-transform: uppercase;
  font-size: ${props => props.theme.designTokens.typography.h2Size};
`

export const SplashLinksContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: ${props => props.theme.designTokens.spacing.large};
  & button {
    font-size: ${props => props.theme.designTokens.typography.sizeDefault};
    text-transform: uppercase;
  }
`

export const SplashContainer = styled('div')`
  align-self: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FinePrint = styled('div')`
  font-size: ${props => props.theme.designTokens.typography.xsmall};
  color: ${props => props.theme.designTokens.color.brandPrimary};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.designTokens.spacing.xsmall};
  box-sizing: border-box;
  & > * {
    display: flex;
    justify-content: space-between;
  }
`
export const SplashLink = styled(Link)`
  color: ${props => props.theme.designTokens.color.brandPrimary};
  text-transform: uppercase;
`

export const SplashForm = styled.form`
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.designTokens.typography.sizeDefault};

  /* target ButtonError's RegularButton to control height (important for alignment when error border present) */
  & ${BoilerPlateButton} {
    margin-top: ${props => props.theme.designTokens.spacing.medium};
    text-transform: uppercase;
    font-size: ${props => props.theme.designTokens.typography.large};
    padding-top: ${props => props.theme.designTokens.spacing.xsmall};
    padding-bottom: ${props => props.theme.designTokens.spacing.xsmall};
    height: inherit;
    border: none;
  }

  & ${$RedBorderButton} {
    &:hover {
      box-shadow: ${props =>
        `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.primaryBackgroundColor}`};
    }
  }

  & label {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: ${props => props.theme.designTokens.spacing.medium};
  }
`

const splashInputStylingString = props => {
  return ` 
    margin-bottom: ${props.theme.designTokens.spacing.xlarge}
    input {
      background-color: transparent;
        color: ${props.theme.designTokens.color.textLight};
        font-size: ${props.theme.designTokens.typography.sizeDefault};
    }
    ${$TextInputContainer}{
      border: none;
      border-bottom: solid ${props.theme.designTokens.border.width.medium} ${props.theme.designTokens.color.textLight};
    }
  ${$TextInputContainer}:focus-within {
      outline: none;
    }
 `
}

export const SplashPasswordInput = styled(PasswordInput)`
  ${props => splashInputStylingString(props)}
`
export const SplashTextInput = styled(TextInput)`
  ${props => splashInputStylingString(props)}
`
