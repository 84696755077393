export const reducer = (state, action) => {
  switch (action.type) {
    case 'receivedUserInfo': {
      const userInfo = action.payload
      const initiallyCheckedGroups = userInfo.groups
        .map(group => group.name)

      return {
        ...state,
        ...userInfo,
        groups: initiallyCheckedGroups,
        hasApiReturned: true,
      }
    }
    case 'editUserServerError':
      return {
        ...state,
        editUserServerError: 'Unable to modify account. Please try again.',
      }
    case 'deleteUserServerError':
      return {
        ...state,
        deleteUserServerError: 'Unable to delete account. Please try again.',
      }
    case 'showDeleteConfirm':
      return { ...state, showDeleteConfirm: true }
    case 'cancelDelete':
      return {
        ...state,
        showDeleteConfirm: false,
        deleteUserServerError: undefined,
      }
    case 'showResetTokenConfirm':
      return { ...state, showResetTokenConfirm: true }
    case 'resetResetTokenConfirm':
      return {
        ...state,
        showResetTokenConfirm: false,
        resetTokenServerError: undefined,
      }

    case 'showResetPassword':
      return { ...state, showResetPassword: true }
    case 'resetResetPassword':
      return {
        ...state,
        showResetPassword: false,
        resetPasswordError: undefined
      }
    case 'resetPasswordError':
      return { ...state, resetPasswordError: 'Wrong password, please try again.' }

    default:
      throw new Error()
  }
}
