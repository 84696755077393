import PropTypes from 'prop-types'
import { getServerBaseUrl, sortByNameCaseInsensitive } from '../helpers'

class UserService {
  constructor(authenticationService) {
    this.authenticationService = authenticationService
    this.axiosWithAuth = authenticationService.getAxiosInstanceWithAuthHeaders()
    this.authenticatedUser = authenticationService.user
    this.userTypesPromise = this.#getUserTypes()
  }

  getUsers = organization => {
    if (!organization.id) {
      // save a round trip to the server if a 'current organization' hasnt been initialized yet
      return new Promise((_resolve, reject) =>
        reject(new Error('No organization selected')),
      )
    }

    const usersUrl = `${getServerBaseUrl()}user/organization/${organization.id}`

    return this.userTypesPromise.then(userTypes => {
      return this.axiosWithAuth.get(usersUrl).then(response => {
        return sortByNameCaseInsensitive(
          response.data.data.map(userInfo => ({
            authToken: userInfo.auth_token,
            groups: userInfo.groups,
            id: userInfo.id,
            name: userInfo.username,
            role: this.#getUserTypeByID(userTypes, userInfo.user_type_id).type,
          })),
        )
      })
    })
  }

  getUser = userId => {
    const userUrl = `${getServerBaseUrl()}user/${userId}`

    return this.userTypesPromise.then(userTypes => {
      return this.axiosWithAuth.get(userUrl).then(response => {
        const {
          auth_token, // eslint-disable-line camelcase
          username,
          user_type_id, // eslint-disable-line camelcase
          nad_27_preferred, // eslint-disable-line camelcase
        } = response.data
        const user = {
          authToken: auth_token,
          name: username,
          nad27Preferred: nad_27_preferred,
          role: this.#getUserTypeByID(userTypes, user_type_id).type,
          ...response.data,
        }

        return user
      })
    })
  }

  addUser = userObj => {
    const newUserUrl = `${getServerBaseUrl()}user/`

    return this.userTypesPromise.then(userTypes => {
      const body = {
        user_type_id: userTypes.find(type => type.type === userObj.role).id,
        ...userObj,
      }

      return this.axiosWithAuth.post(newUserUrl, body).then(response => {
        return response.data
      })
    })
  }

  deleteUser = id => {
    const deleteUserUrl = `${getServerBaseUrl()}user/${id}`

    return this.axiosWithAuth.delete(deleteUserUrl)
  }

  editUser = userInfo => {
    // eslint-disable-next-line camelcase
    const { id, role, active, nad_27_preferred, auth_token } = userInfo
    const editUserUrl = `${getServerBaseUrl()}user/update/${id}`

    return this.userTypesPromise.then(userTypes => {
      const body = {
        user_type_id: userTypes.find(type => type.type === role).id,
        active,
        auth_token,
        nad_27_preferred,
      }

      return this.axiosWithAuth.put(editUserUrl, body)
    })
  }

  updateUserPreferences = (userId, preferences) => {
    const userPreferenceUrl = `${getServerBaseUrl()}user/preferences/${userId}`

    return this.axiosWithAuth.put(userPreferenceUrl, preferences)
  }

  resetOwnAuthToken = () => {
    return this.resetAuthToken(this.authenticatedUser.userId).then(response => {
      this.authenticationService.updateUserProperty(
        'authToken',
        response.data.auth_token,
      )
    })
  }

  resetAuthToken = userId => {
    return this.axiosWithAuth.put(
      `${getServerBaseUrl()}user/auth/reset/${userId}`,
    )
  }

  sendFeedback = values => {
    return new Promise(resolve => {
      resolve(values)
    })
  }

  updateGroupMembership = (userId, groupIds) => {
    const url = `${getServerBaseUrl()}user_layer_group/user/${userId}`

    return this.axiosWithAuth.put(url, groupIds)
  }

  resetPassword = (passwordsObj, userId) => {
    const resetPasswordUrl = `${getServerBaseUrl()}user/password${
      userId ? `/${userId}` : ''
    }`
    const { newPassword, passwordConfirmation, currentPassword } = passwordsObj
    const body = {
      raw_password: newPassword,
      raw_password_repeat: passwordConfirmation,
      raw_password_caller_current: currentPassword,
    }

    return this.axiosWithAuth.put(resetPasswordUrl, body)
  }

  #getUserTypes = () => {
    const userTypesUrl = `${getServerBaseUrl()}user_type/`

    return this.axiosWithAuth.get(userTypesUrl).then(response => {
      return response.data.data.map(userType => ({
        id: userType.id,
        relativePrivilege: userType.relative_privilege,
        type: userType.type,
      }))
    })
  }

  #getUserTypeByID = (userTypes, id) =>
    userTypes.find(userType => userType.id === id)
}

const userServicePropType = PropTypes.shape({
  getUsers: PropTypes.func,
  getUser: PropTypes.func,
  deleteUser: PropTypes.func,
  editUser: PropTypes.func,
  resetAuthToken: PropTypes.func,
  sendFeedback: PropTypes.func,
  addUser: PropTypes.func,
  resetPassword: PropTypes.func,
  updateUserPreferences: PropTypes.func,
})

const userInfoPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  role: PropTypes.string,
  authToken: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.string),
})

export default UserService
export { userServicePropType, userInfoPropType }
