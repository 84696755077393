import axios from 'axios'
import PropTypes from 'prop-types'
import * as FormData from 'form-data'
import { getServerBaseUrl } from '../helpers'

// const baseUrl = getServerBaseUrl()
// temp hack since baseUrl is returning 500 error at the moment
const baseUrl = `${getServerBaseUrl()}`

class AuthenticationService {
  user = null

  constructor() {
    this.user = this.getUserFromStorage()
  }

  authenticate = (username, password) => {
    const form = new FormData()

    form.append('username', username)
    form.append('password', password)

    return axios.post(`${baseUrl}auth/login`, form).then(response => {
      const jwt = response.data.access_token
      const jwtClaims = JSON.parse(atob(jwt.split('.')[1]))
      const jwtAccess = jwtClaims['ps.access']
      const authorization = (accessProperties => {
        if (accessProperties.is_superuser) {
          return 'superuser'
        }
        if (accessProperties.is_admin) {
          return 'admin'
        }

        return 'general'
      })(jwtAccess)

      this.user = Object.freeze({
        jwt,
        userId: jwtAccess.user_id,
        name: jwtClaims.sub,
        organizationId: jwtAccess.organization_id,
        authorization,
        authToken: response.data.auth_token,
        session: {
          expires: jwtClaims.exp * 1000,
        },
      })
      this.setUserInStorage(this.user)

      return this.user
    })
  }

  checkAuthentication = () => {
    return new Promise((resolve, reject) => {
      const userDetail = this.getUserFromStorage()

      if (userDetail) {
        axios
          .get(baseUrl, {
            headers: {
              Authorization: `Bearer ${userDetail.jwt}`,
            },
          })
          .then(() => resolve(this.user))
          .catch(() => reject(new Error('not authenticated')))
      } else {
        reject(new Error('not authenticated'))
      }
    })
  }

  logout = () => {
    this.getAxiosInstanceWithAuthHeaders()
      .post(`${baseUrl}auth/logout`)
      .finally(() => {
        sessionStorage.clear()
      })
  }

  getAxiosInstanceWithAuthHeaders = () => {
    const user = this.getUserFromStorage()

    if (user) {
      return axios.create({
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      })
    }
    throw new Error(
      'You must be logged in to use getAxiosInstanceWithAuthHeaders',
    )
  }

  setUserInStorage = user => {
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  getUserFromStorage = () => {
    const storedUser = sessionStorage.getItem('user')

    return storedUser === null ? null : JSON.parse(storedUser)
  }

  updateUserProperty = (propertyName, propertyValue) => {
    this.setUserInStorage(
      Object.freeze(
        // eslint's preferred approach results in a runtime error when auth_token is updated
        // eslint-disable-next-line prefer-object-spread
        Object.assign({}, this.getUserFromStorage(), {
          [propertyName]: propertyValue,
        }),
      ),
    )
  }
}
export const authorizationLevelPropType = PropTypes.oneOf([
  'superuser',
  'admin',
  'general',
  'unauthorized',
])
export const authenticationServicePropType = PropTypes.shape({
  authenticate: PropTypes.func,
  checkAuthentication: PropTypes.func,
  getAxiosInstanceWithAuthHeaders: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    authorization: authorizationLevelPropType,
  }),
})

export default AuthenticationService
