import { faCog, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import {
  getReactTableHeaders,
  Table,
  Td,
  Tr,
} from '../../generic/TableAndPagination'
import {
  AboveTableButtonContainer,
  AboveTableButtonErrorContainer,
  RowForTableFilterAndButtons,
  StuffAboveTableContainer,
} from '../../appMiscStyledComponents'
import {
  userServicePropType,
  groupServicePropType,
} from '../../../dataServices'
import {
  useFilterableTableDataAndState,
  useReactTableWithRestrictedFeatures,
} from '../../../helpers'
import AddRemoveThingToFromGroupButtons, {
  useAddRemoveFromGroupButtonState,
} from '../../AddRemoveThingToFromGroupButtons'
import { authorizationLevelPropType } from '../../../dataServices/authenticationService'
import { ButtonPrimary } from '../../generic/buttonStyledComponents'
import { ErrorMessage } from '../../generic/ErrorStyledComponents'
import { H1 } from '../../generic/TextStyledComponents'
import { organizationPropType } from '../../../dataServices/organizationService'
import FilterBar from '../../generic/FilterBar'
import HiddenText from '../../generic/HiddenText'
import IconButton from '../../generic/IconButton'
import NoFilterResults from '../../NoFilterResults'
import NoItemsFromApi from '../../NoItemsFromApi'
import StatusIndicator from '../../generic/StatusIndicator/StatusIndicator'

/**
 * Accounts page
 */
const CustomAboveTableButtonContainer = styled(AboveTableButtonContainer)`
  @media (max-width: 1280px) {
    & button {
      padding-left: ${props => props.theme.designTokens.spacing.small};
      padding-right: ${props => props.theme.designTokens.spacing.small};
      font-size: ${props => props.theme.designTokens.typography.xsmall};
    }
    & svg {
      margin-right: ${props => props.theme.designTokens.spacing.xsmall};
    }
  }
`
const Accounts = ({
  authorizationLevel,
  currentOrganization,
  groupService,
  onAddedToGroup,
  onAddUser,
  onEditUser,
  onRemovedFromGroup,
  shouldRerender,
  usersService,
}) => {
  const isAuthorized =
    authorizationLevel === 'admin' || authorizationLevel === 'superuser' // this is an allow list for added 'security'

  const columns = useMemo(
    () => [
      {
        Header: 'Account Name',
        accessor: 'name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Groups',
        accessor: row => row.groups.map(group => group.name).sort().join(', '),
      },
      {
        Header: 'Auth Token',
        accessor: 'authToken',
      },
    ],
    [],
  )

  const getUsers = useCallback(
    () => usersService.getUsers(currentOrganization),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrganization, usersService, shouldRerender],
  )
  const {
    tableData,
    setTableData,
    unfilteredTableData,
    hasApiReturned,
    doesApiReturnData,
  } = useFilterableTableDataAndState(getUsers)

  const {
    headerGroups,
    rows,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    selectedFlatRows,
  } = useReactTableWithRestrictedFeatures(
    {
      columns,
      data: tableData,
    },
    true,
  )

  const selectedRowIds = useMemo(
    () => selectedFlatRows?.map(row => row.original.id),
    [selectedFlatRows],
  )

  const addRemoveFromGroupButtonState = useAddRemoveFromGroupButtonState({
    selectedIds: selectedRowIds,
    groupService,
    currentOrganization,
    onAddedToGroup,
    onRemovedFromGroup,
    nameOfThingsToBeAddedRemoved: 'accounts',
    shouldFetchGroups: isAuthorized,
    addExecutorReturningPromise: groupService.addGroupUserMembership,
    removeExecutorReturningPromise: groupService.removeGroupUserMembership
  })

  const userFilter = filterQuery => {
    const lowerCaseQuery = filterQuery.toLowerCase()
    const filterFunction = user => {
      return (
        user.name.toLowerCase().includes(lowerCaseQuery) ||
        user.role.toLowerCase().includes(lowerCaseQuery)
      )
    }
    const filteredUsers = unfilteredTableData.current.filter(filterFunction)

    setTableData(filteredUsers)
  }

  const resetFilter = () => {
    setTableData(unfilteredTableData.current)
  }

  const NoUsers = () =>
    doesApiReturnData ? (
      <NoFilterResults />
    ) : (
      <NoItemsFromApi itemType="account" onAddItem={() => { onAddUser(addRemoveFromGroupButtonState.groupList) }} />
    )

  const FilterableTable = () =>
    tableData.length ? (
      <Table {...getTableProps()}>
        <thead>{getReactTableHeaders(headerGroups)}</thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)

            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>
                    {cell.column.id === 'authToken' ? (
                      <HiddenText
                        text={cell.render('Cell')}
                        hideTooltip="Hide Token"
                        showTooltip="Show Token"
                      />
                    ) : (
                      cell.render('Cell')
                    )}
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={<FontAwesomeIcon icon={faCog} />}
                    tooltip="Modify"
                    ariaLabel="Modify"
                    onClick={() => {
                      onEditUser(row.original.id, addRemoveFromGroupButtonState.groupList)
                    }}
                  />
                </Td>
              </Tr>
            )
          })}
        </tbody>
      </Table>
    ) : (
      <NoUsers />
    )

  return (
    <>
      <H1 data-testid="page-title">Accounts</H1>
      {hasApiReturned && isAuthorized ? (
        <>
          <StuffAboveTableContainer>
            <RowForTableFilterAndButtons>
              {doesApiReturnData && (
                <FilterBar onFilter={userFilter} onReset={resetFilter} />
              )}
              <CustomAboveTableButtonContainer>
                {!!tableData.length && (
                  <>
                    <AddRemoveThingToFromGroupButtons
                      addRemoveFromGroupButtonState={
                        addRemoveFromGroupButtonState
                      }
                    />

                    <ButtonPrimary onClick={() => { onAddUser(addRemoveFromGroupButtonState.groupList) }}>
                      <FontAwesomeIcon icon={faUserPlus} />
                      Create Account
                    </ButtonPrimary>
                  </>
                )}
              </CustomAboveTableButtonContainer>
            </RowForTableFilterAndButtons>

            {addRemoveFromGroupButtonState.addRemoveErrorMessage && (
              <AboveTableButtonErrorContainer>
                <ErrorMessage>
                  {addRemoveFromGroupButtonState.addRemoveErrorMessage}
                </ErrorMessage>
              </AboveTableButtonErrorContainer>
            )}
          </StuffAboveTableContainer>

          <FilterableTable />
        </>
      ) : (
        <StatusIndicator />
      )}
    </>
  )
}

Accounts.propTypes = {
  authorizationLevel: authorizationLevelPropType.isRequired,
  currentOrganization: organizationPropType.isRequired,
  groupService: groupServicePropType.isRequired,
  onAddedToGroup: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  onRemovedFromGroup: PropTypes.func.isRequired,
  shouldRerender: PropTypes.number.isRequired,
  usersService: userServicePropType.isRequired,
}

export default Accounts
