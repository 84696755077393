import styled, { css } from 'styled-components/macro'

export const Table = styled('table')`
  background-color: ${props => props.theme.designTokens.color.backgroundPage};
  flex-grow: 1;
  width: 100%;
  thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
`

export const Th = styled('th')(
  props => css`
    background-color: ${props.theme.designTokens.color.backgroundTh};
    color: ${props.theme.designTokens.color.textLight};
    font-weight: normal;
    padding: ${props.theme.designTokens.spacing.small};
    text-align: left;
    text-transform: uppercase;
    text-align: ${props.align || 'left'};

    ${props.isSorted &&
      props.isSortedDescending &&
      `
      &::after {
        content: ' \u25bc';
      }
    `}
    ${props.isSorted &&
      !props.isSortedDescending &&
      `
      &::after {
        content: ' \u25b2';
      }
    `}
  `,
)

export const Td = styled('td')`
  padding: ${props => props.theme.designTokens.spacing.small};
  text-align: ${props => props.align || 'left'};
  overflow-wrap: break-word;
`
export const Tr = styled('tr')`
  &:nth-child(even) {
    background-color: ${props =>
      props.theme.designTokens.color.backgroundSecondary};
  }
`
export const TrSelectable = styled.tr(
  props => css`
    &:nth-child(even) {
      background-color: ${props.selected
        ? props.theme.designTokens.color.brandPrimary
        : props.theme.designTokens.color.backgroundSecondary};
    }
    &:hover {
      background-color: ${props.selected
        ? props.theme.designTokens.color.brandPrimary
        : props.theme.designTokens.color.brandSecondary};
    }
    ${props.selected
      ? `background-color: ${props.theme.designTokens.color.brandPrimary}`
      : 'background-color: inherit'}
  `,
)

export const ThForCheckboxes = styled(Th)`
  width: 50px;
`
