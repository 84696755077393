import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'

import { ButtonCaution } from './generic/buttonStyledComponents'
import { Column, Row, RowRight } from './generic/layoutStyledComponents'

export const StyledBody = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: ${props => props.theme.designTokens.app.appMinHeight};
  min-width: ${props => props.theme.designTokens.app.appMinWidth};
`

// intended to hold things like filter above tables
export const RowForTableFilterAndButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
export const StuffAboveTableContainer = styled.div`
  margin-bottom: ${props => props.theme.designTokens.spacing.large};
`

export const AboveTableButtonContainer = styled.div`
  display: flex;
  & button {
    margin-left: ${props => props.theme.designTokens.spacing.small};
  }
`
export const AboveTableButtonErrorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.designTokens.spacing.xxsmall};
`

export const RowContainer = styled(Column)`
  & > ${Row} {
    border-bottom: ${props => props.theme.designTokens.border.secondary};
  }
  & > :last-child {
    border-bottom: none;
  }
`

export const RowBottomButtons = styled(RowRight)`
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  background: white;
  width: 100%;
  flex-wrap: wrap;
  border-top: ${props => props.theme.designTokens.border.primary};
  && {
    border-bottom: none;
  }
  & > * {
    margin-left: ${props => props.theme.designTokens.spacing.small};
  }
`
// this is intended for form buttons with a left delete button, and cancel and submit on the right
export const RowBottomButtons2Right1Left = styled(RowBottomButtons)`
  justify-content: space-between;
  & div {
    display: flex;
  }

  & div > button {
    margin-left: ${props => props.theme.designTokens.spacing.small};
  }
  /* Left button, accounting for the parent div on ButtonError when error present */
  ${ButtonCaution}, & > div {
    margin-left: 0;
  }
`

export const RowBottomButtonErrorRight = styled(RowRight)`
  && {
    border-bottom: none;
  }
  padding-top: 0;
  margin-top: calc(
    -${props =>
        props.theme.designTokens.neededForCalculations.rowPaddingBottom} + ${props => props.theme.designTokens.spacing.xsmall}
  );
`

export const RowBottomButtonErrorLeft = styled(RowBottomButtonErrorRight)`
  justify-content: flex-start;
`

export const TableMessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`
export const TableMessageFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.designTokens.typography.h2Size};
  margin-right: ${props => props.theme.designTokens.spacing.medium};
`

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  .Toastify__toast--default {
    background-color: ${props =>
      props.theme.designTokens.toast.backgroundColor};
    border: ${props => props.theme.designTokens.toast.border};
    color: ${props => props.theme.designTokens.toast.color};
  }
`
