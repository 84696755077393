import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'

import {
  RowBottomButtonErrorRight,
  RowBottomButtons,
  RowContainer,
} from '../appMiscStyledComponents'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { Column, Row } from '../generic/layoutStyledComponents'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Form, FormSectionLabel } from '../generic/formStyledComponents'
import { GroupCheckboxes } from './GroupCheckboxes'
import { SidePanelTitle } from '../SidePanelStyledComponents'
import ErrorBox from '../generic/ErrorBox'
import TextInput from '../generic/TextInput'
import PasswordInput from '../generic/PasswordInput'

export const FormView = ({ state, formik, onClose }) => (
  <Form onSubmit={formik.handleSubmit}>
    <RowContainer>
      <Row>
        <SidePanelTitle>
          <FontAwesomeIcon icon={faUserPlus} />
          Create a new Account
        </SidePanelTitle>
      </Row>
      <Row>
        <Column>
          <FormSectionLabel>Role</FormSectionLabel>
          <ErrorBox errorMessage={formik.errors.role}>
            <Column>
              <label htmlFor="general">
                <input
                  type="radio"
                  id="general"
                  name="role"
                  value="general"
                  checked={formik.values.role === 'general'}
                  onChange={() => {
                    formik.setFieldValue('role', 'general')
                  }}
                />
                General User
              </label>
              <label htmlFor="admin">
                <input
                  type="radio"
                  id="admin"
                  name="role"
                  value="admin"
                  checked={formik.values.role === 'admin'}
                  onChange={() => {
                    formik.setFieldValue('role', 'admin')
                  }}
                />
                Admin
              </label>
              <label htmlFor="server">
                <input
                  type="radio"
                  id="server"
                  name="role"
                  value="server"
                  checked={formik.values.role === 'server'}
                  onChange={() => {
                    formik.setFieldValue('role', 'server')
                  }}
                />
                Server
              </label>
            </Column>
          </ErrorBox>
        </Column>
      </Row>

      <Row>
        <Column>
          <FormSectionLabel>
            User Name
            <TextInput
              name="username"
              {...formik.getFieldProps('username')}
              errorMessage={formik.errors.username}
            />
          </FormSectionLabel>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormSectionLabel>Password
            <PasswordInput
              id="password"
              errorMessage={formik.errors.password}
              {...formik.getFieldProps('password')}
            />
          </FormSectionLabel>
          <FormSectionLabel>Confirm Password
            <PasswordInput
              id="passwordConfirm"
              errorMessage={formik.errors.passwordConfirm}
              {...formik.getFieldProps('passwordConfirm')}
            />
          </FormSectionLabel>
        </Column>
      </Row>
      <Row>
        <Column>
          <label htmlFor="nad27Preferred">
            <input
              type="checkbox"
              id="nad27Preferred"
              name="nad27Preferred"
              value="nad27Preferred"
              checked={formik.values.nad27Preferred}
              onChange={formik.handleChange}
            />
            Use NAD27 for WMS
          </label>
        </Column>
      </Row>
      <Row>
        <Column>
          <GroupCheckboxes state={state} formik={formik} />
        </Column>
      </Row>
      <RowBottomButtons>
        <ButtonTertiary type="button" onClick={onClose}>
          Close
        </ButtonTertiary>
        <ButtonPrimary type="submit" isError={!!state.newUserServerError}>
          <FontAwesomeIcon icon={faUserPlus} />
          Create Account
        </ButtonPrimary>
      </RowBottomButtons>
      {state.newUserServerError && (
        <RowBottomButtonErrorRight>
          <ErrorMessage>{state.newUserServerError}</ErrorMessage>
        </RowBottomButtonErrorRight>
      )}
    </RowContainer>
  </Form>
)

FormView.propTypes = {
  state: PropTypes.shape({
    availableGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    newUserServerError: PropTypes.string,
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      role: PropTypes.string,
      isServerAccount: PropTypes.bool,
      nad27Preferred: PropTypes.bool
    }),
    getFieldProps: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    errors: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}
