import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

/**
 * Icon only button with optional tooltip
 */
const tooltipOffset = '12px'

const StyledIconButton = styled('button')`
  border-radius: ${props =>
    props.theme.designTokens.button.buttonIconBorderRadius};
  border: ${props => props.theme.designTokens.button.buttonIconBorder};
  background-color: ${props =>
    props.theme.designTokens.button.buttonIconBackgroundColor};
  padding: ${props => props.theme.designTokens.spacing.xsmall};
  color: ${props => props.theme.designTokens.button.buttonIconTextColour};
  font-size: ${props => props.theme.designTokens.typography.sizeDefault};
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      props.theme.designTokens.button.buttonIconHoverBackgroundColor};
  }
`
const Centered = styled.div`
  /* IE11 doesnt vertically align if min-height used (which would be more flexible for larger icons) */
  height: ${props => props.theme.designTokens.button.buttonIconHeight};
  width: ${props => props.theme.designTokens.button.buttonIconWidth};
  display: flex;
  justify-content: center;
  align-items: center;
`
const AbsoluteAnchor = styled.div`
  position: relative;
`

const TooltipContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Tooltip = styled.div`
  position: absolute;
  text-align: center;
  bottom: calc(100% + ${tooltipOffset});
  width: ${props => props.theme.designTokens.tooltip.width};
  font-size: ${props => props.theme.designTokens.tooltip.fontSize};
  color: ${props => props.theme.designTokens.color.textLight};
  background: ${props => props.theme.designTokens.tooltip.backgroundColor};
  border-radius: ${props => props.theme.designTokens.tooltip.borderRadius};
  padding: ${props => props.theme.designTokens.spacing.xsmall};
  z-index:99;
`
const Arrow = styled.div`
  position: absolute;
  top: -${tooltipOffset};
  border-top: 5px solid
    ${props => props.theme.designTokens.tooltip.backgroundColor};
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
`

const IconButton = props => {
  const { icon, tooltip, ariaLabel } = props
  const [isToolTipShown, setIsToolTipShown] = useState(false)

  return (
    <AbsoluteAnchor>
      <TooltipContainer>
        {isToolTipShown && tooltip && (
          <>
            <Tooltip>{tooltip}</Tooltip>
            <Arrow />
          </>
        )}

        <StyledIconButton
          data-tooltip={tooltip}
          aria-label={ariaLabel}
          onMouseEnter={() => setIsToolTipShown(true)}
          onMouseLeave={() => setIsToolTipShown(false)}
          {...props}
        >
          <Centered>{icon}</Centered>
        </StyledIconButton>
      </TooltipContainer>
    </AbsoluteAnchor>
  )
}

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string,
}
IconButton.defaultProps = {
  tooltip: null,
}

export default IconButton
