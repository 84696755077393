import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { FormSectionLabel } from '../generic/formStyledComponents'
import { ErrorText } from '../generic/ErrorStyledComponents'

const NoGroupsText = styled.div`
  font-style: italic;
`

export const GroupCheckboxes = ({ state, formik }) => (
  <>
    <FormSectionLabel>Groups</FormSectionLabel>
    {state.availableGroups.length < 1 && !state.groupServerError && (
      <NoGroupsText>
        This organization has no groups to choose from.
      </NoGroupsText>
    )}
    {state.groupServerError && <ErrorText>{state.groupServerError}</ErrorText>}
    {state.availableGroups.map(group => {
      const domId = `group_${group.id}`

      return (
        <label htmlFor={domId} key={domId}>
          <input
            type="checkbox"
            id={domId}
            name="groups"
            value={group.name}
            checked={formik.values.groups.includes(group.name)}
            onChange={formik.handleChange}
          />
          {group.name}
        </label>
      )
    })}
  </>
)

GroupCheckboxes.propTypes = {
  state: PropTypes.shape({
    availableGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groupServerError: PropTypes.string,
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({ groups: PropTypes.arrayOf(PropTypes.string) }),
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
}
