import {
  faLock,
  faLayerGroup,
  faMap,
  // faComments,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'

import { designTokens } from '../../theme'
import NavInfoDisplay from './NavInfoDisplay'
// import ButtonError from '../generic/ButtonError/ButtonError'
import { authorizationLevelPropType } from '../../dataServices/authenticationService'
import { organizationPropType } from '../../dataServices/organizationService'
import AccountIcon from './AccountIcon'
/**
 * Main 'navigation'
 */

const spacingTokens = designTokens.spacing

const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${designTokens.app.navWidth};
  background-color: ${designTokens.app.navBackgroundColor};
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`` // we turn this into a styled component so we can target FontAwesomeIcons with NavAnchor
const StyledAccountIcon = styled(AccountIcon)`
  width: 1.125em;
  height: 1.125em;
`

const NavAnchor = styled(NavLink)`
  padding: ${spacingTokens.medium};
  text-decoration: none;
  color: ${designTokens.color.textLight};
  text-transform: ${designTokens.app.navAnchorTextTransform};
  &:hover {
    border-right: ${designTokens.app.navBorderActive};
    background-color: rgba(255, 255, 255, 0.2);
    transition-property: border-right;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
  }
  &.active {
    color: ${designTokens.color.text};
    background-color: ${designTokens.color.backgroundPage};
    border-right: ${designTokens.app.navBorderActive};
  }
  & ${StyledFontAwesomeIcon}, ${StyledAccountIcon} {
    margin-right: ${designTokens.spacing.medium};
    min-width: 21px; /* whatever the widest icon is, for alignment*/
  }
`

const NavGroupDefault = styled.div`
  display: flex;
  flex-direction: column;
`
const NavGroupDarker = styled(NavGroupDefault)`
  background-color: ${designTokens.app.navBackGroundColorDarker};
`

// const ButtonFeedback = styled(ButtonError)`
//   font-size: ${props => props.theme.designTokens.typography.xsmall};
//   padding: 0 ${props => props.theme.designTokens.spacing.medium};
//   background-color: transparent;
//   color: ${designTokens.color.textLight}BF; /* BF = .75 transparancy in hex*/
//   text-align: start;
// `

const Nav = ({
  authorizationLevel,
  currentOrganization,
  onOrganizationClick,
  // onFeedbackClick,
}) => {
  const showOrgManager = authorizationLevel === 'superuser'

  const showRestrictedLinks =
    authorizationLevel === 'superuser' || authorizationLevel === 'admin'

  return (
    <NavContainer>
      <div>
        <NavGroupDefault>
          <NavInfoDisplay
            info={currentOrganization?.name || ''}
            onClick={onOrganizationClick}
            tooltip="Manage Orgs"
            showButton={showOrgManager}
          />
          <NavAnchor to="/" exact>
            <StyledFontAwesomeIcon icon={faLock} />
            Auth Token
          </NavAnchor>
        </NavGroupDefault>

        <NavGroupDarker>
          {showRestrictedLinks && (
            <NavAnchor to="/groups">
              <StyledFontAwesomeIcon icon={faUsers} />
              Groups
            </NavAnchor>
          )}
          <NavAnchor to="/services">
            <StyledFontAwesomeIcon icon={faMap} />
            Services
          </NavAnchor>
          {showRestrictedLinks && (
            <NavAnchor to="/accounts">
              <StyledAccountIcon />
              Accounts
            </NavAnchor>
          )}

          <NavAnchor to="/layers">
            <StyledFontAwesomeIcon icon={faLayerGroup} />
            Layers
          </NavAnchor>
        </NavGroupDarker>
      </div>
      {/* <NavGroupDarker>
        <ButtonFeedback type="button" onClick={onFeedbackClick}>
          <StyledFontAwesomeIcon icon={faComments} />
          Send Feedback...
        </ButtonFeedback>
      </NavGroupDarker> */}
    </NavContainer>
  )
}

Nav.propTypes = {
  authorizationLevel: authorizationLevelPropType.isRequired,
  currentOrganization: organizationPropType.isRequired,
  onOrganizationClick: PropTypes.func.isRequired,
  // onFeedbackClick: PropTypes.func.isRequired,
}

export default Nav
