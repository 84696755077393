import React from 'react'

// eslint-disable-next-line react/prop-types
const AccountIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="966.5"
    // height="748.138"
    viewBox="0 0 966.5 748.138"
    className={className}
  >
    <g>
      <g>
        <path
          d="M428.6,592.138H410.964a247.287,247.287,0,0,1-99.964,21V664.5a42,42,0,0,0,42,42H579.255C560.847,640.612,500.281,592.138,428.6,592.138Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
        <path
          d="M311,370.5v84a42,42,0,0,0,42,42h20.014a148.056,148.056,0,0,0,82.127-168H353A42,42,0,0,0,311,370.5Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
        <path
          d="M311,214.138V244.5a42,42,0,0,0,42,42h85.163A148.062,148.062,0,0,0,311,214.138Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
        <path
          d="M941,538.5H376.129a186.664,186.664,0,0,1-51.617,11.148,41.909,41.909,0,0,0-12.854,23.481,207.768,207.768,0,0,0,86.673-19.165l3.975-1.826H428.6c93.869,0,172.555,66.2,191.856,154.362H941a42,42,0,0,0,42-42v-84A42,42,0,0,0,941,538.5ZM794,654a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,794,654Zm84,0a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,878,654Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
        <path
          d="M941,328.5H495.981a187.883,187.883,0,0,1-53.631,168H941a42,42,0,0,0,42-42v-84A42,42,0,0,0,941,328.5ZM794,444a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,794,444Zm84,0a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,878,444Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
        <path
          d="M941,118.5H353a42,42,0,0,0-42,42v13.638c76.77,0,142.928,46.259,172.094,112.362H941a42,42,0,0,0,42-42v-84A42,42,0,0,0,941,118.5ZM794,234a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,794,234Zm84,0a31.5,31.5,0,1,1,31.5-31.5A31.5,31.5,0,0,1,878,234Z"
          transform="translate(-17 -118)"
          stroke="#fff"
          strokeMiterlimit="10"
          fill="currentColor"
        />
      </g>
      <path
        d="M311,530.138a168,168,0,1,0-168-168A167.988,167.988,0,0,0,311,530.138Zm117.6,42H406.681a228.473,228.473,0,0,1-191.362,0H193.4c-97.387,0-176.4,79.012-176.4,176.4v54.6a63.017,63.017,0,0,0,63,63H542a63.017,63.017,0,0,0,63-63v-54.6C605,651.15,525.987,572.138,428.6,572.138Z"
        transform="translate(-17 -118)"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default AccountIcon
