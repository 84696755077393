import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dataServicePropType } from '../../dataServices'
import { Form } from '../generic/formStyledComponents'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { Row, Column } from '../generic/layoutStyledComponents'
import { RowBottomButtons } from '../appMiscStyledComponents'
import TextInput from '../generic/TextInput'

const getSelectInputStylingString = props => `
  padding: 0 ${props.theme.designTokens.spacing.small};
  border: ${props.theme.designTokens.border.input};
  box-sizing: border-box;
  height: ${props.theme.designTokens.form.inputHeight};
  min-width: ${props.theme.designTokens.form.inputWidth};
  background-color: ${props.theme.designTokens.color.inputBackground};
  font-size: ${props.theme.designTokens.typography.sizeDefault};
  &:focus {
    outline: ${props.theme.designTokens.border.inputFocusOutline};
  }
`
const FormColumn = styled(Column)`
  label {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    select {
      ${props => getSelectInputStylingString(props)}
    }
    &:hover {
      background: #f5f5f7;
    }
  }
`
const initialFormValues = {
  name: '',
  humanName: '',
  description: '',
  type: 0,
  source: '',
  layer: '',
  wms: false,
  wmts: false,
  wmsGrid: '',
  sourceGrid: '',
  backfill: false,
  transparent: false,
  sourceExtra: '',
  geom: '',
  disableCache: false,
}

const LayerFormView = ({ layerService, layerId, onClose }) => {
  const [initialValues, setInitialValues] = useState(initialFormValues)
  const [gridAndSourceTypesObj, setGridAndSourceTypesObj] = useState({})
  const [encryptOrDecryptMode, setEncryptOrDecryptMode] = useState(null)
  const isNewLayer = layerId === undefined

  useEffect(() => {
    layerService.gridAndSourceTypesPromise.then(gridAndSourceTypes => {
      setGridAndSourceTypesObj(gridAndSourceTypes)

      if (isNewLayer) {
        setInitialValues({
          ...initialValues,
          type: gridAndSourceTypes.sourceTypes[0].id,
        })
      } else {
        layerService.getLayer(layerId).then(layer => {
          setInitialValues(layer)
          setEncryptOrDecryptMode(layer.source === '' ? null : 'decrypt')
        })
      }
    })
  }, [isNewLayer, layerId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = values => {
    const { gridTypes, sourceTypes } = gridAndSourceTypesObj

    const layerValues = {
      title: values.humanName.trim() || null,
      description: values.description.trim() || null,
      source_type_id: values.type ?? sourceTypes[0].id,
      source_grid_id: values.sourceGrid ? gridTypes[0].id : null,
      source_url: values.source.trim() || null,
      source_layer: values.layer.trim() || null,
      wms_grid_id: values.wmsGrid ? gridTypes[0].id : null,
      wms: values.wms,
      wmts: values.wmts,
      backfill: values.backfill,
      transparent: values.transparent,
      source_extra: values.sourceExtra.trim() || null,
      coverage: values.geom.trim() || null,
      disable_cache: values.disableCache,
    }

    if (isNewLayer) {
      layerValues.name = values.name.trim()
      layerService
        .createLayer(layerValues)
        .then(() => {
          toast.success(`Created new layer: ${layerValues.name}`)
          onClose()
        })
        .catch(() => {
          toast.error(
            `Could not create new layer, ${layerValues.name}, due to server error`,
          )
          onClose()
        })
    } else {
      layerService
        .updateLayer(layerId, layerValues)
        .then(() => {
          toast.success(`Updated layer: ${values.name}`)
          onClose()
        })
        .catch(() => {
          toast.error(
            `Could not update layer, ${values.name}, due to server error`,
          )
          onClose()
        })
    }
  }

  const handleEncryptOrDecrypt = (source, setFieldValue) => {
    if (encryptOrDecryptMode === 'encrypt') {
      layerService.encryptSource(source)
        .then(encryptedSource => {
          setFieldValue('source', encryptedSource)
          setEncryptOrDecryptMode('decrypt')
        })
    } else {
      layerService.decryptSource(source)
      .then(decryptedSource => {
        setFieldValue('source', decryptedSource)
        setEncryptOrDecryptMode('encrypt')
      })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Please enter a layer name.')
        .trim(),
      humanName: Yup.string(),
      description: Yup.string(),
      type: Yup.number().isRequired,
      source: Yup.string(),
      layer: Yup.string(),
      sourceExtra: Yup.string(),
      geom: Yup.string(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  })

  const getSourceIcon = (source, setFieldValue) => {
    if (encryptOrDecryptMode === null || source === '') {
      return <></>
    }

    return (
      <FontAwesomeIcon
        icon={encryptOrDecryptMode === 'encrypt' ? faEyeSlash : faEye}
        onClick={() => handleEncryptOrDecrypt(source, setFieldValue)}
      />
    )
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <FormColumn>
            <label htmlFor="name">
              <span>Name</span>
              <TextInput
                name="name"
                errorMessage={formik.errors.name}
                disabled={!isNewLayer}
                readOnly={!isNewLayer}
                {...formik.getFieldProps('name')}
              />
            </label>
            <label htmlFor="humanName">
              <span>HumanName</span>
              <TextInput
                name="humanName"
                {...formik.getFieldProps('humanName')}
              />
            </label>
            <label htmlFor="description">
              <span>Description</span>
              <TextInput
                name="description"
                {...formik.getFieldProps('description')}
              />
            </label>
            <label htmlFor="type">
              <span>Type</span>
              <select
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
              >
                {gridAndSourceTypesObj.sourceTypes?.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="source">
              <span>Source</span>
              <TextInput
                name="source"
                icon={getSourceIcon(formik.values.source, formik.setFieldValue)}
                type={encryptOrDecryptMode === 'decrypt' ? 'password' : 'text'}
                {...formik.getFieldProps('source')}
              />
            </label>
            <label htmlFor="layer">
              <span>Layer</span>
              <TextInput name="layer" {...formik.getFieldProps('layer')} />
            </label>
            <label htmlFor="wms">
              <span>WMS</span>
              <input
                type="checkbox"
                id="wms"
                name="wms"
                value="wms"
                checked={formik.values.wms}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="wmts">
              <span>WMTS</span>
              <input
                type="checkbox"
                id="wmts"
                name="wmts"
                value="wmts"
                checked={formik.values.wmts}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="wmsGrid">
              <span>WMS_Grid</span>
              <select
                name="wmsGrid"
                value={formik.values.wmsGrid}
                onChange={formik.handleChange}
              >
                <option value="">None</option>
                {gridAndSourceTypesObj.gridTypes?.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="sourceGrid">
              <span>SourceGrid</span>
              <select
                name="sourceGrid"
                value={formik.values.sourceGrid}
                onChange={formik.handleChange}
              >
                <option value="">None</option>
                {gridAndSourceTypesObj.gridTypes?.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="backfill">
              <span>Backfill</span>
              <input
                type="checkbox"
                id="backfill"
                name="backfill"
                value="backfill"
                checked={formik.values.backfill}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="transparent">
              <span>Transparent</span>
              <input
                type="checkbox"
                id="transparent"
                name="transparent"
                value="transparent"
                checked={formik.values.transparent}
                onChange={formik.handleChange}
              />
            </label>
            <label htmlFor="sourceExtra">
              <span>SourceExtra</span>
              <textarea
                name="sourceExtra"
                {...formik.getFieldProps('sourceExtra')}
              />
            </label>
            <label htmlFor="geom">
              <span>Geom</span>
              <TextInput
                name="geom"
                errorMessage={formik.errors.geom}
                {...formik.getFieldProps('geom')}
              />
            </label>
            <label htmlFor="disableCache">
              <span>DisableCache</span>
              <input
                type="checkbox"
                id="disableCache"
                name="disableCache"
                value="disableCache"
                checked={formik.values.disableCache}
                onChange={formik.handleChange}
              />
            </label>
          </FormColumn>
        </Row>
        <RowBottomButtons>
          <ButtonTertiary onClick={onClose}>Cancel</ButtonTertiary>
          <ButtonPrimary type="submit">Save Changes</ButtonPrimary>
        </RowBottomButtons>
      </Form>
    </>
  )
}

LayerFormView.propTypes = {
  layerService: dataServicePropType.isRequired,
  layerId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}

LayerFormView.defaultProps = {
  layerId: undefined,
}

export default LayerFormView
