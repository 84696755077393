import { Layer } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { CloseButton } from '../SidePanelStyledComponents'

/**
 * Side Panel for account profile, adding an new user, etc.
 */

const ContentsContainer = styled.div`
  overflow: auto;
  min-width: ${props => props.theme.designTokens.app.sidePanelContentsMinWidth};
  width: 90vw;
  max-height: 80vh;
  margin-bottom: 10vh;
`

const defaultModalPosition = "center"

const Modal = ({ onClose, children, position, allowDismissal }) => {
  return (
    <Layer
      position={position}
      onClickOutside={allowDismissal ? onClose : () => {}}
      onEsc={allowDismissal ? onClose : () => {}}
      animation="none"
    >
      {allowDismissal && <CloseButton onClose={onClose} />}
      <ContentsContainer>{children}</ContentsContainer>
    </Layer>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  allowDismissal: PropTypes.bool
}

Modal.defaultProps = {
  position: defaultModalPosition,
  allowDismissal: true
}

ContentsContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export {
  Modal,
  defaultModalPosition
}
