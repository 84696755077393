import PropTypes from 'prop-types'
import { getServerBaseUrl, sortByNameCaseInsensitive } from '../helpers'

class GroupService {
  constructor(authenticationService) {
    this.axiosWithAuth = authenticationService.getAxiosInstanceWithAuthHeaders()
  }

  getGroups = organization => {
    const orgId = organization?.id
    const groupsUrl = `${getServerBaseUrl()}layer_group/organization/${orgId}`

    if (!orgId) {
      // save a round trip to the server if a 'current organization' hasnt been initialized yet
      return new Promise((_resolve, reject) =>
        reject(new Error('No organization selected')),
      )
    }

    return this.axiosWithAuth.get(groupsUrl).then(response => {
      return sortByNameCaseInsensitive(
        response.data.data.map(group => ({
          name: group.name,
          id: group.id,
          accounts: group.users,
          layers: group.layers,
        })),
      )
    })
  }

  createGroup = (name, organizationId) => {
    const body = {
      name,
      organization_id: organizationId,
      origin_id: null,
    }
    const url = `${getServerBaseUrl()}layer_group/`

    return this.axiosWithAuth.post(url, body)
  }

  deleteGroup = group => {
    if (!group.id) {
      return new Error('Malformed group object')
    }
    const url = `${getServerBaseUrl()}layer_group/${group.id}`

    return this.axiosWithAuth.delete(url)
  }

  editGroup = editedGroup => {
    if (!editedGroup.id || !editedGroup.name) {
      return new Error('Malformed group object')
    }

    const url = `${getServerBaseUrl()}layer_group/${editedGroup.id}`

    return this.axiosWithAuth.put(url, editedGroup)
  }

  addGroupLayerMembership = (groupId, idsToBeAdded) => {
    return this.axiosWithAuth
      .put(`${getServerBaseUrl()}layer/layer_group/${groupId}`, idsToBeAdded)
      .then(response => response.data.data)
  }

  removeGroupLayerMembership = (groupId, idsToBeRemoved) => {
    return this.axiosWithAuth
      .delete(`${getServerBaseUrl()}layer/layer_group/${groupId}`, {
        data: idsToBeRemoved,
      })
      .then(response => response.data.data)
  }

  addGroupUserMembership = (groupId, idsToBeAdded) => {
    return this.axiosWithAuth
      .put(
        `${getServerBaseUrl()}user_layer_group/layer_group/${groupId}`,
        idsToBeAdded,
      )
      .then(response => response.data.data)
  }

  removeGroupUserMembership = (groupId, idsToBeAdded) => {
    return this.axiosWithAuth
      .delete(`${getServerBaseUrl()}user_layer_group/layer_group/${groupId}`, {
        data: idsToBeAdded,
      })
      .then(response => response.data.data)
  }
}

const groupServicePropType = PropTypes.shape({
  createGroup: PropTypes.func,
  deleteGroup: PropTypes.func,
  editGroup: PropTypes.func,
  getGroups: PropTypes.func,
  addGroupLayerMembership: PropTypes.func,
  removeGroupLayerMembership: PropTypes.func,
})

const groupPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accounts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  layers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export default GroupService
export { groupServicePropType, groupPropType }
