import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React from 'react'

import {
  RowBottomButtonErrorRight,
  RowBottomButtons,
} from '../appMiscStyledComponents'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { Column, Row } from '../generic/layoutStyledComponents'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Form, FormSectionLabel } from '../generic/formStyledComponents'
import { TinyText } from '../generic/TextStyledComponents'
import TextInput from '../generic/TextInput'

const NewOrgFormView = ({ onCreateOrganization, serverError, onClose }) => {
  const formik = useFormik({
    initialValues: {
      orgName: '',
      defaultGroup: '',
    },
    onSubmit: values => {
      onCreateOrganization(values)
    },
    validationSchema: Yup.object({
      orgName: Yup.string()
        .trim()
        .required('Please enter an organization name.')
        .matches(
          /^[a-zA-Z0-9-_ ]+$/,
          'Organization names may contain _, -, letters, numbers, and spaces.',
        ),
      defaultGroup: Yup.string()
        .trim()
        .matches(
          /^[a-zA-Z0-9 ]+$/,
          'Group names may contain spaces, letters, and numbers.',
        ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Column>
          <FormSectionLabel htmlFor="orgName">
            Organization Name
            <TextInput
              name="orgName"
              errorMessage={formik.errors.orgName}
              {...formik.getFieldProps('orgName')}
            />
          </FormSectionLabel>

          <FormSectionLabel htmlFor="defaultGroup">
            Default Group Name
            <TextInput
              name="defaultGroup"
              errorMessage={formik.errors.defaultGroup}
              {...formik.getFieldProps('defaultGroup')}
            />
          </FormSectionLabel>

          <TinyText>Leave Blank to skip creating a default group.</TinyText>
        </Column>
      </Row>
      <RowBottomButtons>
        <ButtonTertiary type="button" onClick={onClose}>
          Cancel
        </ButtonTertiary>
        <ButtonPrimary type="submit" isError={!!serverError}>
          Create Organization
        </ButtonPrimary>
      </RowBottomButtons>
      {serverError && (
        <RowBottomButtonErrorRight>
          <ErrorMessage>{serverError}</ErrorMessage>
        </RowBottomButtonErrorRight>
      )}
    </Form>
  )
}

NewOrgFormView.propTypes = {
  onCreateOrganization: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  serverError: PropTypes.string.isRequired,
}

export default NewOrgFormView
