/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { ErrorMessage } from '../ErrorStyledComponents'

/**
 * A regular button, that has a red border when there is an error
 */

// assumes icon is always in front of text

export const BoilerPlateButton = styled.button`
  font-size: ${props => props.theme.designTokens.typography.sizeDefault};
  border: none;
  line-height: ${props => props.theme.designTokens.form.inputHeight};
  padding: 0 ${props => props.theme.designTokens.spacing.large};
  & > svg {
    margin-right: ${props => props.theme.designTokens.spacing.medium};
  }
  &:disabled {
    background-color: ${props =>
      props.theme.designTokens.button.disabledBackgroundColor};
    color: ${props => props.theme.designTokens.button.disabledTextColor};
    &:hover {
      box-shadow: none;
    }
  }
`

export const $RedBorderButton = styled(BoilerPlateButton)`
  && {
    border: ${props => props.theme.designTokens.border.error};
    line-height: calc(
      2em - (${props => props.theme.designTokens.border.borderErrorWidth} * 2)
    );
  }
  &&:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.color.error}`};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonError = props => {
  const { isError, children, errorMessage } = props

  return isError || errorMessage ? (
    <Container>
      <$RedBorderButton {...props}>{children}</$RedBorderButton>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <BoilerPlateButton {...props}>{children}</BoilerPlateButton>
  )
}

ButtonError.propTypes = {
  isError: PropTypes.bool,
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
}
ButtonError.defaultProps = { isError: false, errorMessage: undefined }

export default ButtonError
