import { Grommet } from 'grommet'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'
import React, { useState, useReducer } from 'react'

import { authenticationServicePropType } from '../../dataServices'
import { grommetThemeWithDesignTokens, designTokens } from '../../theme'
import { StyledToastContainer } from '../appMiscStyledComponents'
import Accounts from '../pages/Accounts/Accounts'
import AuthToken from '../pages/AuthToken'
import Groups from '../pages/Groups'
import Layers from '../pages/Layers'
import Layout from '../Layout'
import Login from '../pages/Login'
import Services from '../pages/Services'
import StatusIndicator from '../generic/StatusIndicator'
import StyleDefaults from '../StyleDefaults'
import useAppHandlers from './App.useAppHandlers'
import useAuthenticatedState from './App.useAuthenticatedState'
import useInitializeCurrentOrganization from './App.useInitializeCurrentOrganization'
import useModal from './App.useModal'

import './App.css'
import 'react-toastify/dist/ReactToastify.min.css'
import Nav from '../Nav'
import AccountDropdown from '../AccountDropdown'
/*
  App.js is used to control all React Context Providers
*/

function App({ authenticationService }) {
  /*
  This shouldRerender/forceRerender isnt the best approach
  to forcing components to rerender. Architecture could be used
  to avoid , and it and was explored, but implementation of that
  refactor would extend timelines and, increase bug risk when we
  have a lack of robust QA, and increase costs disporportionately
  to this 'hack' which is React convention - they suggest avoiding it -
  but its 'good enough'.
  https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  */
  const [shouldRerender, forceRerender] = useReducer(x => x + 1, 0)

  const [currentOrganization, setCurrentOrganization] = useState({})
  const {
    authorizationLevel,
    authToken,
    dataService,
    groupService,
    isAuthenticationInitialized,
    isLoggedIn,
    organizationService,
    setAuthenticatedState,
    setIsLoggedIn,
    user,
    userService
  } = useAuthenticatedState(authenticationService)

  const {
    handleCloseModal,
    Modal,
    setModalPosition,
    isModalOpen,
    openModal,
  } = useModal()

  const {
    loginError,
    handleAddedToGroup,
    handleAddUser,
    handleCreateGroupClick,
    handleEditGroupClick,
    handleEditUser,
    // handleFeedbackClick,
    handleLogin,
    handleLogout,
    handleOrganizationManagerClick,
    handleRemovedFromGroup,
    handleResetAuthToken,
    handleOpenPasswordResetForm
  } = useAppHandlers(
    {
      setCurrentOrganization,
      setAuthenticatedState,
      setIsLoggedIn,
      forceRerender,
      setModalPosition,
      openModal,
      handleCloseModal,
    },
    {
      groupService,
      userService,
      organizationService,
      authenticationService,
      dataService
    },
    user,
    currentOrganization,
  )

  useInitializeCurrentOrganization({
    authorizationLevel,
    currentOrganization,
    getOrganization: organizationService?.getOrganization,
    openOrganizationManager: handleOrganizationManagerClick,
    setCurrentOrganization,
    user,
  })

  const layoutProps = {
    left: (
      <Nav
        authorizationLevel={authorizationLevel}
        onOrganizationClick={() => handleOrganizationManagerClick(true)}
        // onFeedbackClick={handleFeedbackClick}
        currentOrganization={currentOrganization}
      />
    ),
    menu: (
      <AccountDropdown
        onLogout={handleLogout}
        user={user}
        userService={userService}
        onResetAuthToken={handleResetAuthToken}
        onResetPassword={handleOpenPasswordResetForm}
      />
    ),
  }

  const LoginView = () => (
    <>
      {isAuthenticationInitialized ? (
        <>
          <Login onSubmit={handleLogin} errorMessage={loginError} />
        </>
      ) : (
        <StatusIndicator />
      )}
    </>
  )

  return (
    <ThemeProvider theme={{ designTokens }}>
      <StyleDefaults>
        <Grommet theme={grommetThemeWithDesignTokens}>
          <StyledToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {isLoggedIn && (
            <Layout {...layoutProps}>
              {isModalOpen && <Modal />}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <AuthToken token={authToken} />}
                />
                <Route
                  path="/layers"
                  render={() => (
                    <Layers
                      authorizationLevel={authorizationLevel}
                      currentOrganization={currentOrganization}
                      dataService={dataService}
                      groupService={groupService}
                      onAddedToGroup={handleAddedToGroup}
                      onRemovedFromGroup={handleRemovedFromGroup}
                      shouldRerender={shouldRerender}
                    />
                  )}
                />
                <Route
                  path="/groups"
                  render={() => (
                    <Groups
                      authorizationLevel={authorizationLevel}
                      currentOrganization={currentOrganization}
                      groupService={groupService}
                      onCreateGroupClick={handleCreateGroupClick}
                      onEditGroup={handleEditGroupClick}
                      shouldRerender={shouldRerender}
                    />
                  )}
                />
                <Route
                  path="/accounts"
                  render={() => (
                    <Accounts
                      authorizationLevel={authorizationLevel}
                      currentOrganization={currentOrganization}
                      groupService={groupService}
                      onAddedToGroup={handleAddedToGroup}
                      onAddUser={handleAddUser}
                      onEditUser={handleEditUser}
                      onRemovedFromGroup={handleRemovedFromGroup}
                      shouldRerender={shouldRerender}
                      usersService={userService}
                    />
                  )}
                />
                <Route
                  path="/services"
                  render={() => (
                    <Services
                      currentUser={user}
                      userService={userService}
                      organization={currentOrganization}
                    />
                  )}
                />
              </Switch>
            </Layout>
          )}
          {!isLoggedIn && (
            <Switch>
              <Route exact path="/" component={LoginView} />
              <Route path="/login/:emailOrUsername?" component={LoginView} />
              <Route path="/services" component={LoginView} />
              <Route path="/accounts" component={LoginView} />
              <Route path="/layers" component={LoginView} />
              <Route path="/groups" component={LoginView} />
            </Switch>
          )}
        </Grommet>
      </StyleDefaults>
    </ThemeProvider>
  )
}

App.propTypes = {
  authenticationService: authenticationServicePropType.isRequired,
}

export default App
