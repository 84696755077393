import styled from 'styled-components'

export const Row = styled('div')`
  display: flex;
  align-items: center;

  padding: ${props => props.theme.designTokens.spacing.large}
    ${props => props.theme.designTokens.spacing.xlarge}
    ${props => props.theme.designTokens.neededForCalculations.rowPaddingBottom};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const RowRight = styled(Row)`
  justify-content: flex-end;
`
export const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
  align-items: baseline;
`
export const RowSpaceAround = styled(Row)`
  justify-content: space-around;
`
export const RowCenter = styled(Row)`
  justify-content: center;
`
