import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React from 'react'
import {
  SplashForm,
  SplashPasswordInput,
  SplashTextInput,
} from '../../Splash/Splash.styled'
import { ButtonPrimary } from '../../generic/buttonStyledComponents'

/**
 * A login form component with basic input validation to check
 * that field values are present.
 */
function LoginForm({ onSubmit, className, setUsername, errorMessage }) {
  const { emailOrUsername = '' } = useParams()

  const formik = useFormik({
    initialValues: {
      username: emailOrUsername,
      password: '',
    },
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      username: Yup.string().required('Please enter a user name.'),
      password: Yup.string().required('Please enter a password.'),
    }),
    enableReinitialize: true,
  })

  return (
    <SplashForm onSubmit={formik.handleSubmit} className={className}>
      <label htmlFor="username">User Name</label>

      <SplashTextInput
        id="username"
        name="username"
        errorMessage={formik.errors.username}
        value={formik.values.username}
        onChange={event => {
          formik.handleChange(event)
          setUsername(event.target.value)
        }}
      />
      <label htmlFor="password">Password</label>
      <SplashPasswordInput
        id="password"
        name="password"
        errorMessage={formik.errors.password}
        {...formik.getFieldProps('password')}
      />

      <ButtonPrimary type="submit" errorMessage={errorMessage}>
        Log In
      </ButtonPrimary>
    </SplashForm>
  )
}

LoginForm.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setUsername: PropTypes.func.isRequired,
}

LoginForm.defaultProps = {
  className: '',
}

export default LoginForm
