import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import React, { useReducer } from 'react'

import { Row } from '../generic/layoutStyledComponents'
import { OrganizationServicePropType } from '../../dataServices/organizationService'
import StatusIndicator from '../generic/StatusIndicator/StatusIndicator'
import { useFilterableTableDataAndState } from '../../helpers'
import NewOrgFormView from './NewOrgFormView'
import ModifyOrgView from './ModifyOrgView'
import ManageOrgLayersView from './ManageOrgLayersView'
import ManageAllLayersView from './ManageAllLayersView'
import { SidePanelTitle, ModalRowContainer } from '../SidePanelStyledComponents'
import TableView from './TableView'
import LayerFormView from './LayerFormView'
import { dataServicePropType } from '../../dataServices'

/**
 * Switch Organization. Add, Modify.
 */

const viewReducer = (state, action) => {
  switch (action.type) {
    case 'edit':
      return { ...state, view: 'edit', currentOrgBeingEdited: action.payload }
    case 'editOrgError':
      return {
        ...state,
        editOrgError: 'Unable to edit organization. Please Try Again.',
      }
    case 'new':
      return { ...state, view: 'new' }
    case 'newOrgError':
      return {
        ...state,
        newOrgError: 'Unable to create organization. Please Try Again.',
      }
    case 'table':
      return { ...state, view: 'table' }
    case 'reset':
      return {
        view: 'table',
        currentOrgBeingEdited: undefined,
        layerBeingEdited: undefined,
        editOrgError: undefined,
        newOrgError: undefined,
      }
    case 'manageOrgLayers':
      return { ...state, view: 'manageOrgLayers', currentOrgBeingEdited: action.payload }
    case 'manageAllLayers':
      return { ...state, view: 'manageAllLayers' }
    case 'showLayerForm':
      return { ...state, view: 'showLayerForm', layerBeingEdited: action.payload }

    default:
      throw new Error()
  }
}

const OrganizationManager = ({
  organizationService,
  onClose,
  onOrganizationSelect,
  allowDismissal,
  layerService
}) => {
  const [viewState, dispatchView] = useReducer(viewReducer, {
    view: 'table',
    editOrgError: '',
    newOrgError: '',
  })
  const tableDataAndState = useFilterableTableDataAndState(organizationService.getOrganizations)

  const { hasApiReturned, tableData } = tableDataAndState

  const handleCreateOrganizationSubmit = ({ orgName, defaultGroup }) => {
    organizationService
      .newOrganization({ name: orgName, defaultGroup })
      .then(newOrg => {
        tableDataAndState.addItemToTable(newOrg)
        dispatchView({ type: 'reset' })
        toast.success(`The organization, '${orgName}', has been added.`)
      })
      .catch(() => {
        dispatchView({ type: 'newOrgError' })
      })
  }

  const handleEditOrganizationSubmit = modifiedOrg => {
    organizationService
      .editOrganization(modifiedOrg)
      .then(updatedOrg => {
        tableDataAndState.updateItemInTable(updatedOrg)
        dispatchView({ type: 'reset' })
        toast.success(
          `The organization, '${modifiedOrg.name}', has been modified.`,
        )
      })
      .catch(() => {
        dispatchView({ type: 'editOrgError' })
      })
  }

  const handleEditOrganizationOpenView = id => {
    const organization = tableData.filter(item => item.id === id)

    dispatchView({
      type: 'edit',
      payload: organization[0],
    })
  }

  const handleEditOrganizationLayersOpenView = id => {
    const organization = tableData.filter(item => item.id === id)

    dispatchView({
      type: 'manageOrgLayers',
      payload: organization[0],
    })
  }

  const handleManageAllLayersOpenView = () => { dispatchView({ type: 'manageAllLayers' }) }

  const handleShowLayerFormView = layerId => dispatchView({ type: 'showLayerForm', payload: layerId })

  return hasApiReturned ? (
    <>
      <ModalRowContainer>
        <Row>
          <SidePanelTitle>
            <FontAwesomeIcon icon={faCalculator} />
            Manage Organizations
          </SidePanelTitle>
        </Row>
        {viewState.view === 'table' && (
          <>
            <TableView
              layerService={layerService}
              tableDataAndState={tableDataAndState}
              onOrganizationEdit={handleEditOrganizationOpenView}
              onOrganizationManageLayers={handleEditOrganizationLayersOpenView}
              onManageAllLayers={handleManageAllLayersOpenView}
              onOrganizationSelect={onOrganizationSelect}
              onNewOrganization={() => dispatchView({ type: 'new' })}
              onClosePanel={onClose}
              allowDismissal={allowDismissal}
            />
          </>
        )}
        {viewState.view === 'new' && (
          <NewOrgFormView
            onCreateOrganization={handleCreateOrganizationSubmit}
            serverError={viewState.newOrgError}
            onClose={() => dispatchView({ type: 'table' })}
          />
        )}
        {viewState.view === 'edit' && (
          <ModifyOrgView
            organization={viewState.currentOrgBeingEdited}
            onEditOrganization={handleEditOrganizationSubmit}
            serverError={viewState.editOrgError}
            onClose={() => dispatchView({ type: 'reset' })}
          />
        )}
        {viewState.view === 'manageOrgLayers' && (
          <ManageOrgLayersView
            organization={viewState.currentOrgBeingEdited}
            organizationService={organizationService}
            layerService={layerService}
            onClose={() => dispatchView({ type: 'table' })}
          />
        )}
        {viewState.view === 'manageAllLayers' && (
          <ManageAllLayersView
            layerService={layerService}
            onEditLayer={handleShowLayerFormView}
            onNewLayer={handleShowLayerFormView}
            onClose={() => dispatchView({ type: 'table' })}
          />
        )}
        {viewState.view === 'showLayerForm' && (
          <LayerFormView
            layerService={layerService}
            layerId={viewState.layerBeingEdited}
            onClose={() => dispatchView({ type: 'manageAllLayers' })}
          />
        )}
      </ModalRowContainer>
    </>
  ) : (
    <StatusIndicator />
  )
}

OrganizationManager.propTypes = {
  onClose: PropTypes.func.isRequired,
  organizationService: OrganizationServicePropType.isRequired,
  onOrganizationSelect: PropTypes.func,
  allowDismissal: PropTypes.bool,
  layerService: dataServicePropType.isRequired,
}

OrganizationManager.defaultProps = {
  onOrganizationSelect: () => {},
  allowDismissal: true
}

export default OrganizationManager
