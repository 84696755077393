import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  RowBottomButtonErrorRight,
  RowBottomButtons,
  RowContainer,
} from '../appMiscStyledComponents'

import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { Row } from '../generic/layoutStyledComponents'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Form } from '../generic/formStyledComponents'
import { groupServicePropType, validationConfigs } from '../../dataServices'
import { organizationPropType } from '../../dataServices/organizationService'
import { SidePanelTitle } from '../SidePanelStyledComponents'
import TextInput from '../generic/TextInput'

/**
 * New Group Form
 */

const NewGroup = ({
  onCancel,
  onSuccess,
  currentOrganization,
  groupServiceInstance,
}) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  const handleSubmit = values => {
    setSubmitErrorMessage('')
    groupServiceInstance
      .createGroup(values.name, currentOrganization.id)
      .then(response => {
        onSuccess(response.data)
      })
      .catch(() => {
        setSubmitErrorMessage('Unable to create group. Please try again.')
      })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationConfigs.group,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <RowContainer>
        <Row>
          <SidePanelTitle>
            <FontAwesomeIcon icon={faUsers} />
            Create a new Group
          </SidePanelTitle>
        </Row>

        <Row>
          <label htmlFor="name">
            New Group Name
            <TextInput
              id="name"
              {...formik.getFieldProps('name')}
              errorMessage={formik.errors.name}
            />
          </label>
        </Row>
        <RowBottomButtons>
          <ButtonTertiary type="button" onClick={onCancel}>
            Cancel
          </ButtonTertiary>
          <ButtonPrimary type="submit" isError={!!submitErrorMessage}>
            Create Group
          </ButtonPrimary>
        </RowBottomButtons>
        {!!submitErrorMessage && (
          <RowBottomButtonErrorRight>
            <ErrorMessage>{submitErrorMessage}</ErrorMessage>
          </RowBottomButtonErrorRight>
        )}
      </RowContainer>
    </Form>
  )
}

NewGroup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  groupServiceInstance: groupServicePropType.isRequired,
  currentOrganization: organizationPropType.isRequired,
}

export default NewGroup
