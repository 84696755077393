import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { faCog, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFilterableTableDataAndState } from '../../helpers'
import { Table, Td, Th, Tr } from '../generic/TableAndPagination'
import { Row } from '../generic/layoutStyledComponents'
import NoFilterResults from '../NoFilterResults'
import FilterBar from '../generic/FilterBar'
import IconButton from '../generic/IconButton'
import { ButtonPrimary, ButtonTertiary } from '../generic/buttonStyledComponents'
import {
  NoDataContainer,
  StyledRowSpaceBetween,
} from './OrganizationManager.styled'
import {
  RowBottomButtons,
} from '../appMiscStyledComponents'
import NoItemsFromApi from '../NoItemsFromApi'
import StatusIndicator from '../generic/StatusIndicator/StatusIndicator'
import { dataServicePropType } from '../../dataServices'

const getDataQuery = (layerService) => {
  return () => {
    return layerService.getAllLayers()
      .then((allLayers) => {

        return allLayers.map(layer => {
          return {
            id: layer.id,
            name: layer.name,
            title: layer.title,
            description: layer.description,
            visible: true
          }
        }).sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        })
      })
  }
}

const layerFilterTimeoutDelay = 200

const ManageAllLayersView = ({ layerService, onClose, onEditLayer, onNewLayer }) => {
  const [layerFilterTimeout, setLayerFilterTimeout] = useState(null)
  const [dataQuery] = useState(() => getDataQuery(layerService))
  const [hasOutstandingChanges, setHasOutstandingChanges] = useState(false)
  const [view, setView] = useState('table')
  const tableDataAndState = useFilterableTableDataAndState(dataQuery)
  const {
    tableData,
    hasApiReturned,
    doesApiReturnData,
    unfilteredTableData,
    setTableData,
  } = tableDataAndState

  useEffect(() => {
    layerService.getLayerConfigStatus()
      .then(status => {
        if (status === 'pending') {
          setHasOutstandingChanges(true)
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleApplyChangesClick = () => {
    setView('showApplyChangesConfirm')
  }

  const handleCloseClick = () => hasOutstandingChanges ? setView('showExitConfirm') : onClose()

  const handleConfirmApplyChanges = () => {
    layerService.approveLayerConfigUpdate()
      .then(() => {
        toast.success("Applying changes to Mapproxy")
        onClose()
      })
      .catch(() => {
        toast.error("Unable to apply mapproxy update")
        onClose()
      })
  }

  const resetFilter = () => setTableData(unfilteredTableData.current)

  const layerFilter = filterQuery => {
    if (layerFilterTimeout) {
      window.clearTimeout(layerFilterTimeout)
      setLayerFilterTimeout(null)
    }
    // Layer list can be large - e.g. ~1,000 rows. Debounce filtering to avoid excessive unnecessary processing
    setLayerFilterTimeout(
      window.setTimeout(() => {
        const lowerCaseQuery = filterQuery.toLowerCase()
        const filterFunction = layer => {
          return (
            layer.title?.toLowerCase().includes(lowerCaseQuery) ||
            layer.name.toLowerCase().includes(lowerCaseQuery) ||
            layer.description?.toLowerCase().includes(lowerCaseQuery)
          )
        }
        /*
        Precedent from other components is to filter unmatched rows out of the list, however this will
        result in any checked layers being removed from formik.values.layers if they are filtered out.
        Usability is improved if layers remain checked when hidden, a users can incrementally build
        the full set of layers by filtering, checking, and re-filtering.
        No significant changes in performance were observed switching from filtering layers to setting
        display: none;
        */
        // eslint-disable-next-line prefer-object-spread
        const filteredLayers = unfilteredTableData.current.map(layer => Object.assign({}, layer, {
          visible: filterFunction(layer)
        }))

        setTableData(filteredLayers)
      }, layerFilterTimeoutDelay)
    )
  }

  const NoData = () => {
    if (doesApiReturnData) {
      return <NoFilterResults />
    }
    if (hasApiReturned) {
      return <NoItemsFromApi itemType="layer" showButton={false} onAddItem={() => {}} />
    }

    return <StatusIndicator />
  }

  const FilterableTable = () =>
    tableData.length ? (
      <Row>
        <Table>
          <thead>
            <Tr>
              <Th>Name</Th>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th />
            </Tr>
          </thead>
          <tbody>
            {tableData.map(layer => {
              return (
                <Tr
                  key={layer.id}
                  style={layer.visible ? {} : { display: 'none' }}>
                  <Td>{layer.name}</Td>
                  <Td>{layer.title}</Td>
                  <Td>{layer.description}</Td>
                  <Td>
                    <IconButton
                      icon={<FontAwesomeIcon icon={faCog} />}
                      tooltip="Modify Layer"
                      ariaLabel="Modify Layer"
                      onClick={() => onEditLayer(layer.id)}
                    />
                  </Td>
                </Tr>
              )
            })}
          </tbody>
        </Table>
      </Row>
    ) : (
      <NoDataContainer>
        <NoData />
      </NoDataContainer>
    )

  return (
    <>
      {view === 'showApplyChangesConfirm' && (
        <>
          <StyledRowSpaceBetween>
            Are you sure you want to apply your changes to Mapproxy?<br/><br/>
            Active WMS and WMTS service users may experience a delay while these changes are processed.
          </StyledRowSpaceBetween>
          <RowBottomButtons>
            <ButtonTertiary onClick={() => setView('table')}>Cancel</ButtonTertiary>
            <ButtonPrimary onClick={handleConfirmApplyChanges}>
              Apply changes
            </ButtonPrimary>
          </RowBottomButtons>
        </>
      )}
      {view === 'showExitConfirm' && (
        <>
          <StyledRowSpaceBetween>
            Are you sure you want to leave without applying your changes to Mapproxy?
          </StyledRowSpaceBetween>
          <RowBottomButtons>
            <ButtonTertiary onClick={() => setView('table')}>Cancel</ButtonTertiary>
            <ButtonPrimary onClick={onClose}>
              Leave without applying changes
            </ButtonPrimary>
          </RowBottomButtons>
        </>
      )}
      {view === 'table' && (
        <>
          {!!unfilteredTableData.current.length && (
            <StyledRowSpaceBetween>
              <FilterBar onReset={resetFilter} onFilter={layerFilter} />
              <ButtonPrimary onClick={() => onNewLayer()}>
                <FontAwesomeIcon icon={faLayerGroup} />
                New Layer
              </ButtonPrimary>
            </StyledRowSpaceBetween>
          )}
          <FilterableTable />
          <RowBottomButtons>
            <ButtonTertiary onClick={handleCloseClick}>Close</ButtonTertiary>
            {hasOutstandingChanges && <ButtonPrimary onClick={handleApplyChangesClick}>Apply changes</ButtonPrimary>}
          </RowBottomButtons>
        </>
      )}
    </>
  )
}

ManageAllLayersView.propTypes = {
  layerService: dataServicePropType.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditLayer: PropTypes.func.isRequired,
  onNewLayer: PropTypes.func.isRequired
}

export default ManageAllLayersView
