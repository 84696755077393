import {
  faUser,
  faPlus,
  faUsers,
  faDatabase,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import { designTokens } from '../../theme/theme.tokens'
import {
  TableMessageContainer,
  TableMessageFontAwesomeIcon,
} from '../appMiscStyledComponents'
import { ButtonPrimary } from '../generic/buttonStyledComponents'

/**
 * Describe your component
 */
const RowTableMessageContainer = styled(TableMessageContainer)`
  flex-direction: column;
`
const FirstRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${designTokens.spacing.xlarge};
`

const NoItemsFromApi = ({ itemType, onAddItem, showButton }) => {
  const capitalizedType =
    itemType.charAt(0).toUpperCase() + itemType.substring(1)
  const getIcon = () => {
    switch (itemType) {
      case 'layer':
        return faDatabase
      case 'group':
        return faUsers
      case 'organization':
        return faCalculator
      default:
        return faUser
    }
  }

  return (
    <>
      <RowTableMessageContainer>
        <FirstRow>
          <TableMessageFontAwesomeIcon icon={getIcon()} />
          <span>There are no {itemType}s to display</span>
        </FirstRow>
        {showButton && (
          <ButtonPrimary onClick={onAddItem}>
            <FontAwesomeIcon icon={faPlus} />
            {`Create New ${capitalizedType}`}
          </ButtonPrimary>
        )}
      </RowTableMessageContainer>
    </>
  )
}

NoItemsFromApi.propTypes = {
  itemType: PropTypes.oneOf([
    'user',
    'group',
    'data',
    'account',
    'layer',
    'organization',
  ]).isRequired,
  onAddItem: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
}

NoItemsFromApi.defaultProps = {
  showButton: true,
}

export default NoItemsFromApi
