import React, { useEffect, useState } from 'react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Menu } from 'grommet'
import { toast } from 'react-toastify'
import { userServicePropType } from '../../dataServices'

/**
 * Dropdown for viewing account profile and logging out
 */

const getCheckboxIcon = userPrefersNad27 => userPrefersNad27 ? faCheckSquare : faSquare

const AccountDropdown = ({
  onLogout,
  onResetPassword,
  onResetAuthToken,
  user,
  userService
}) => {
  const [userPrefersNad27, setUserPrefersNad27] = useState(false)

  useEffect(() => {
    if (userService) {
      userService.getUser(user.userId)
        .then(userResponse => {
          setUserPrefersNad27(userResponse.nad27Preferred)
        })
        .catch(() => {})
    }
  }, [userService, user])

  const handleChangeNad27Preference = (updatedPreference) => {
    userService.updateUserPreferences(user.userId, { nad_27_preferred: updatedPreference })
    .then(() => {
      setUserPrefersNad27(updatedPreference)
      toast.success("NAD27 preference has been updated")
    })
    .catch(() => {
      toast.error("Unable to update NAD27 preference")
    })
  }

  return (
    <Menu
      a11yTitle="User Menu"
      data-testid="account-dropdown"
      label={
        <>
          <FontAwesomeIcon icon={faUserCircle} /> {user.name}
        </>
      }
      items={[
        {
          label: <div><FontAwesomeIcon icon={getCheckboxIcon(userPrefersNad27)}/> Use NAD27 for WMS</div>,
          onClick: () => handleChangeNad27Preference(!userPrefersNad27)
        },
        { label: 'Reset Password', onClick: onResetPassword },
        { label: 'Reset Auth Token', onClick: onResetAuthToken },
        { label: 'Log out', onClick: onLogout },
      ]}
      icon={<FontAwesomeIcon icon={faCaretDown} />}
    />
  )
}

AccountDropdown.propTypes = {
  onLogout: PropTypes.func,
  onResetPassword: PropTypes.func.isRequired,
  onResetAuthToken: PropTypes.func.isRequired,
  userService: userServicePropType,
  user: PropTypes.shape({
    name: PropTypes.string,
    userId: PropTypes.number,
  }),
}

AccountDropdown.defaultProps = {
  onLogout: () => {},
  userService: {},
  user: {}
}
export default AccountDropdown
