import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { ErrorMessage } from '../ErrorStyledComponents'

/**
 * A box with an error border and error message that surrounds children
 */
const ErrorBoxStyling = styled.div`
  border: ${props => props.theme.designTokens.app.borderError};
  min-width: ${props => props.theme.designTokens.form.inputWidth};
  padding-left: ${props => props.theme.designTokens.spacing.small};
  padding-right: ${props => props.theme.designTokens.spacing.small};
  box-sizing: border-box;
`

const ErrorBox = ({ errorMessage, children }) => {
  return errorMessage ? (
    <>
      <ErrorBoxStyling>{children}</ErrorBoxStyling>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  ) : (
    <>{children}</>
  )
}

ErrorBox.propTypes = {
  errorMessage: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
}
ErrorBox.defaultProps = {
  errorMessage: undefined,
}

export default ErrorBox
