/*
This file is to take the place of a potential imported design system

Its an initial stab at developing a token system, and may or may not match
a future design system repo.

Its not meant to create exaustive tokens for anything and everything,
rather, its for basic look and feel and branding.
*/

const color = {
  backgroundCard: '#F5F5F5',
  backgroundPage: '#f6f6f6',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#F0F0F0',
  backgroundTertiary: '#E4E1E4',
  backgroundTh: '#666666',
  brandPrimary: '#fcaf17',
  brandSecondary: '#FCDA95',
  error: '#FF0000',
  inputBackground: '#FFFFFF',
  text: '#4C4B48',
  textDisabled: '#9d9d9d',
  textError: '#FF0000',
  textLight: '#ffffff',
  textSecondary: '#666666',
}

const spacing = {
  xxxsmall: '1px',
  xxsmall: '2px',
  xsmall: '4px',
  small: '10px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
  xxlarge: '48px',
  xxxlarge: '64px',
}

const form = {
  inputHeight: '2em',
  inputWidth: '250px',
}

const borderWidths = {
  xsmall: '1px',
  small: '2px',
  medium: '3px',
  large: '4px',
  xlarge: '5px',
}

const borderStyleComponents = {
  colorPrimary: '#4C4B48',
  colorSecondary: '#EFEFEF',
  colorInput: '#cccccc',
  stylePrimary: 'solid',
  width: borderWidths,
  widthDefault: borderWidths.xsmall,
  borderErrorWidth: borderWidths.small,
}

const border = {
  ...borderStyleComponents,
  error: `${borderStyleComponents.stylePrimary} ${borderStyleComponents.borderErrorWidth} ${color.error}`,
  input: `${borderStyleComponents.stylePrimary} ${borderStyleComponents.width.xsmall} ${borderStyleComponents.colorInput}`,
  inputFocusOutline: `${color.brandPrimary} auto ${borderWidths.medium}`,
  primary: `${borderStyleComponents.stylePrimary} ${borderStyleComponents.width.small} ${borderStyleComponents.colorPrimary}`,
  secondary: `${borderStyleComponents.stylePrimary} ${borderStyleComponents.width.xsmall} ${borderStyleComponents.colorSecondary}`,
}

const fontSize = {
  xsmall: '14px',
  small: '16px',
  medium: '18px',
  large: '22px',
}

const typography = {
  fontFamily: 'arial, sans-serif',
  fontFamilyCode:
    'Source Code Pro, Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
  h1Size: '23px',
  h1TextTransform: 'uppercase',
  h2Size: '23px',
  h2Weight: 'bold',
  h2Margin: '0',
  h3Size: '23px',
  h4Size: '18px',
  ...fontSize,
  sizeDefault: fontSize.medium,
}

const tooltip = {
  backgroundColor: '#696b65',
  borderRadius: '0',
  width: '105px',
  fontSize: typography.xsmall,
}

const toast = {
  backgroundColor: color.backgroundTertiary,
  border: `${border.stylePrimary} ${border.width.xsmall} #000`,
  closeButtonColor: '#000',
  color: color.text,
}

const button = {
  buttonIconBackgroundColor: 'transparent',
  buttonIconBorder: 'none',
  buttonIconBorderRadius: '100%',
  buttonIconHeight: '25px',
  buttonIconHoverBackgroundColor: '#D4D4D4',
  buttonIconTextColour: color.textSecondary,
  buttonIconWidth: '25px',
  cautionBackgroundColor: '#ef0b18',
  disabledBackgroundColor: '#999792',
  disabledTextColor: '#ccc9c3',
  primaryBackgroundColor: color.brandPrimary,
  quaternatyBackgroundColor: '#212121',
  secondaryBackgroundColor: '#8f8f8f',
  tertiaryBackgroundColor: `#6b6965`,
}

const neededForCalculations = {
  rowPaddingBottom: spacing.large,
}

const designTokens = {
  border,
  button,
  color,
  form,
  spacing,
  toast,
  tooltip,
  typography,
  neededForCalculations,
}

export { designTokens }
