import { toast } from 'react-toastify'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Box, Select } from "grommet"
import { Column } from '../../generic/layoutStyledComponents'
import { CopyIcon, CopyButton } from '../../generic/CopyButton'
import { copyText, getServerBaseUrl } from '../../../helpers'
import { H1, H2 } from '../../generic/TextStyledComponents'
import { userInfoPropType, userServicePropType } from "../../../dataServices"
import { organizationPropType } from "../../../dataServices/organizationService"

/**
 * Services Page
 */

const Card = styled.div`
  background-color: ${props => props.theme.designTokens.color.backgroundCard};
  padding: ${props => props.theme.designTokens.spacing.large};
  box-sizing: border-box;
  width: 100%;
`

const CardEntry = styled.div`
  display: flex;
  margin-top: ${props => props.theme.designTokens.spacing.medium};
`

const CardEntryPart = styled(Column)`
  flex-basis: 0;
  flex-grow: 1;
  word-break: break-word;
  :nth-child(1) {
    font-weight: bold;
    padding-right: ${props => props.theme.designTokens.spacing.medium};
  }
  :nth-child(3) {
    padding-left: ${props => props.theme.designTokens.spacing.medium};
  }
`

const CardContainer = styled.div`
  & > * {
    margin-right: ${props => props.theme.designTokens.spacing.medium};
    margin-bottom: ${props => props.theme.designTokens.spacing.medium};
  }
`
const UrlText = styled.div`
  text-decoration: underline;
`

const ServiceUrl = ({ urlData, token, serviceType }) => {
  const urlRef = useRef()
  const tokenPlaceholder = "{AUTH_TOKEN}"
  const tokenRegex = RegExp(token.replace(/(-)/g, (match, $grp1) => {
    return `\\${$grp1}`
  }))
  const urlHasToken = !!urlData.url.match(tokenRegex)

  const handleCopy = () => {
    copyText(urlRef.current.textContent.replace(tokenPlaceholder, token))
    toast(
      <>
        <CopyIcon /> {serviceType} URL copied to clipboard
      </>,
    )
  }

  return (
    <CardEntry>
      <CardEntryPart>{urlData.type}</CardEntryPart>
      <CardEntryPart>
        <UrlText ref={urlRef}>{urlData.url.replace(token, tokenPlaceholder)}</UrlText>
      </CardEntryPart>
      <CardEntryPart>
        <CopyButton onClick={handleCopy}>
          Copy {serviceType} URL {urlHasToken ? 'with selected user\'s auth token' : ''}
        </CopyButton>
      </CardEntryPart>
    </CardEntry>
  )
}

ServiceUrl.propTypes = { urlData: PropTypes.shape({
  url: PropTypes.string,
  type: PropTypes.string,
}).isRequired, token: PropTypes.string.isRequired, serviceType: PropTypes.string.isRequired }

const Services = ({ currentUser, userService, organization }) => {
  const [allUsers, setAllUsers] = useState([])
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(currentUser)

  useEffect(() => {
    if (userService && organization.id) {
      userService.getUsers(organization)
        .then(userResponse => {
          setAllUsers([...userResponse])
          setUsers([...userResponse])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [currentUser, userService, organization])

  const baseUrl = getServerBaseUrl()
  const typeNames = {
    queryString: "Query String Auth Token",
    path: "Path Auth Token",
    header: "HTTP Authorization header required"
  }
  const urlTemplateSets = [{
    service: "WMS",
    urls: [{
      type: typeNames.queryString,
      url: `${baseUrl}wms?authkey=${selectedUser.authToken}&request=GetCapabilities`
    }, {
      type: typeNames.path,
      url: `${baseUrl}streaming/__steamingguid.${selectedUser.authToken}/wms.ashx?request=GetCapabilities`
    }, {
      type: typeNames.header,
      url: `${baseUrl}wms?request=GetCapabilities`
    }]
  }, {
    service: "WMTS",
    urls: [{
      type: typeNames.queryString,
      url: `${baseUrl}wmts?authkey=${selectedUser.authToken}&request=GetCapabilities&service=WMTS`
    }, {
      type: typeNames.path,
      url: `${baseUrl}streaming/__steamingguid.${selectedUser.authToken}/wmts.ashx?request=GetCapabilities&service=WMTS`
    }, {
      type: typeNames.header,
      url: `${baseUrl}wmts?request=GetCapabilities&service=WMTS`
    }]
  }]

  return (
    <>
      <H1 data-testid="page-title">Services</H1>
      <Box width="medium" pad={{ "bottom": "small" }}>
        <Select
          a11yTitle="Account Select"
          data-testid="account-select"
          name="accountSelect"
          size="medium"
          dropHeight="large"
          placeholder="Select Account"
          value={selectedUser}
          options={users}
          labelKey="name"
          valueKey="authToken"
          onChange={({ option }) => setSelectedUser(option)}
          onClose={() => setUsers(allUsers)}
          onSearch={(text) => {
            // The line below escapes regular expression special characters:
            // [ \ ^ $ . | ? * + ( )
            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
            const exp = new RegExp(escapedText, 'i')

            setUsers(allUsers.filter((o) => exp.test(o.name)))
          }}
          emptySearchMessage="User not found"
        />
      </Box>
      <CardContainer>
        {urlTemplateSets.map(templateSet => (
          <Card key={templateSet.service}>
            <H2>{templateSet.service}</H2>
              {templateSet.urls.map(urlData => {
                return <ServiceUrl
                  key={urlData.type}
                  urlData={urlData}
                  token={selectedUser.authToken}
                  serviceType={templateSet.service}
                  />
              })}
          </Card>
        ))}
      </CardContainer>
    </>
  )
}

Services.propTypes = {
  currentUser: userInfoPropType,
  userService: userServicePropType,
  organization: organizationPropType,
}

Services.defaultProps = {
  currentUser: {},
  userService: {},
  organization: {}
}

export default Services
