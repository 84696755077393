import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  getReactTableHeaders,
  Table,
  Td,
  Tr,
} from '../../generic/TableAndPagination'
import AddRemoveThingToFromGroupButtons, {
  useAddRemoveFromGroupButtonState,
} from '../../AddRemoveThingToFromGroupButtons'
import {
  useReactTableWithRestrictedFeatures,
  useFilterableTableDataAndState,
} from '../../../helpers'

import { authorizationLevelPropType } from '../../../dataServices/authenticationService'
import {
  dataServicePropType,
  groupServicePropType,
} from '../../../dataServices'
import { ErrorMessage } from '../../generic/ErrorStyledComponents'
import FilterBar from '../../generic/FilterBar'
import NoFilterResults from '../../NoFilterResults'
import NoItemsFromApi from '../../NoItemsFromApi'
import StatusIndicator from '../../generic/StatusIndicator'
import { organizationPropType } from '../../../dataServices/organizationService'
import { H1 } from '../../generic/TextStyledComponents'
import {
  AboveTableButtonContainer,
  AboveTableButtonErrorContainer,
  RowForTableFilterAndButtons,
  StuffAboveTableContainer,
} from '../../appMiscStyledComponents'

/**
 * 'Layers' page
 */

const Layers = ({
  authorizationLevel,
  currentOrganization,
  dataService,
  groupService,
  onAddedToGroup,
  onRemovedFromGroup,
  shouldRerender,
}) => {
  const getLayers = useCallback(
    () => dataService.getOrganizationLayers(currentOrganization, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrganization, dataService, shouldRerender],
  )

  const {
    tableData,
    setTableData,
    unfilteredTableData,
    hasApiReturned,
    doesApiReturnData,
  } = useFilterableTableDataAndState(getLayers)

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Groups',
        accessor: row => row.groups.map(group => group.name).sort().join(', '),
      },
    ],
    [],
  )

  const sortColumns = useMemo(
    () => [
      {
        id: 'description',
        desc: false,
      },
    ],
    [],
  )

  const showRestrictedFeatures =
    authorizationLevel === 'admin' || authorizationLevel === 'superuser'

  const resetFilter = () => {
    setTableData(unfilteredTableData.current)
  }

  const dataFilter = filterQuery => {
    const lowerCaseQuery = filterQuery.toLowerCase()
    const filterFunction = dataItem => {
      return (
        dataItem.name?.toLowerCase().includes(lowerCaseQuery) ||
        dataItem.description?.toLowerCase().includes(lowerCaseQuery) ||
        dataItem.path?.toLowerCase().includes(lowerCaseQuery)
      )
    }
    const filteredData = unfilteredTableData.current.filter(filterFunction)

    setTableData(filteredData)
  }

  const NoData = () =>
    doesApiReturnData ? (
      <NoFilterResults />
    ) : (
      <NoItemsFromApi itemType="layer" onAddItem={() => {}} />
    )
  const {
    headerGroups,
    rows,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    selectedFlatRows,
  } = useReactTableWithRestrictedFeatures(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: sortColumns,
      }
    },
    showRestrictedFeatures,
  )

  const selectedRowIds = useMemo(
    () => selectedFlatRows?.map(row => row.original.id),
    [selectedFlatRows],
  )

  const addRemoveFromGroupButtonState = useAddRemoveFromGroupButtonState({
    selectedIds: selectedRowIds,
    groupService,
    currentOrganization,
    onAddedToGroup,
    onRemovedFromGroup,
    nameOfThingsToBeAddedRemoved: 'layers',
    shouldFetchGroups: showRestrictedFeatures,
    addExecutorReturningPromise: groupService.addGroupLayerMembership,
    removeExecutorReturningPromise: groupService.removeGroupLayerMembership
  })

  const FilterableTable = () => {
    return (
      <>
        {tableData.length ? (
          <>
            <Table {...getTableProps()}>
              <thead>{getReactTableHeaders(headerGroups, false)}</thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)

                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <Td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  )
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <NoData />
        )}
      </>
    )
  }

  return (
    <>
      <H1 data-testid="page-title">Layers</H1>
      {hasApiReturned ? (
        <>
          <StuffAboveTableContainer>
            <RowForTableFilterAndButtons>
              {doesApiReturnData && (
                <FilterBar onFilter={dataFilter} onReset={resetFilter} />
              )}
              <AboveTableButtonContainer>
                {doesApiReturnData && showRestrictedFeatures && (
                  <>
                    <AddRemoveThingToFromGroupButtons
                      addRemoveFromGroupButtonState={
                        addRemoveFromGroupButtonState
                      }
                    />
                  </>
                )}
              </AboveTableButtonContainer>
            </RowForTableFilterAndButtons>
            {doesApiReturnData && showRestrictedFeatures && (
              <AboveTableButtonErrorContainer>
                {addRemoveFromGroupButtonState.addRemoveErrorMessage && (
                  <ErrorMessage>
                    {addRemoveFromGroupButtonState.addRemoveErrorMessage}
                  </ErrorMessage>
                )}
              </AboveTableButtonErrorContainer>
            )}
          </StuffAboveTableContainer>

          <FilterableTable />
        </>
      ) : (
        <StatusIndicator />
      )}
    </>
  )
}

Layers.propTypes = {
  authorizationLevel: authorizationLevelPropType.isRequired,
  currentOrganization: organizationPropType.isRequired,
  dataService: dataServicePropType.isRequired,
  groupService: groupServicePropType.isRequired,
  onAddedToGroup: PropTypes.func.isRequired,
  onRemovedFromGroup: PropTypes.func.isRequired,
  shouldRerender: PropTypes.number.isRequired,
}

export default Layers
