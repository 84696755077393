import styled from 'styled-components/macro'
import { getTextInputStylingString } from '../TextInput/TextInput'

export const ResetButton = styled.button`
  background-color: ${props => props.theme.designTokens.color.inputBackground};
`
export const SearchInput = styled('input')`
  /* removed look-and-feel to accomodate reset button looking like it is within input */
  background-image: url('filterIcon.svg');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: left 5px center;
  padding-left: 30px;
  border: none;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`
export const SearchForm = styled('form')`
  display: flex;
  flex-direction: row;

  & label {
    font-size: ${props => props.theme.designTokens.typography.xsmall};
    color: ${props => props.theme.designTokens.color.text};
  }
`

export const InputContainer = styled('div')`
  ${props => getTextInputStylingString(props)}
  min-width: 0;
  background-color: ${props => props.theme.designTokens.color.inputBackground};
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 180px;

  @media (min-width: 1060px) {
    width: 345px;
  }
  &:focus-within {
    outline: ${props => props.theme.designTokens.border.inputFocusOutline};
    outline-offset: -1px;
  }
  & button {
    padding: 0 ${props => props.theme.designTokens.spacing.small} 0 0;
    height: 100%;
    border: none;
  }
`
