import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import TextInput from '../TextInput'

/**
 * Hide show password input
 */

const PasswordInput = props => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const EyeButton = () => (
    <FontAwesomeIcon
      icon={showPassword ? faEyeSlash : faEye}
      onClick={toggleShowPassword}
    />
  )

  return (
    <TextInput
      type={showPassword ? 'text' : 'password'}
      icon={<EyeButton />}
      {...props}
    />
  )
}

export default PasswordInput
