import { faPlus, faMinus, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import { BoilerPlateButton } from '../generic/ButtonError/ButtonError'

import { ButtonTertiary } from '../generic/buttonStyledComponents'
import { useAddRemoveFromGroupButtonPropType } from './useAddRemoveFromGroupButtonState'
/**
 * Describe your component
 */
const CustomButton = styled(ButtonTertiary)`
  & > svg:last-of-type {
    margin-left: ${props => props.theme.designTokens.spacing.medium};
    margin-right: 0;
  }
`

const PositionedAncestor = styled.div`
  position: relative;
`
const GroupsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.theme.designTokens.color.backgroundPrimary};
  position: absolute;
  right: 0;
  max-width: 225px;
  border-top: ${props => props.theme.designTokens.border.input};
  z-index: 2;
`

const GroupListItem = styled(BoilerPlateButton)`
  padding: 0 ${props => props.theme.designTokens.spacing.small};
  border: ${props => props.theme.designTokens.border.input};
  border-top: none;
  background-color: ${props =>
    props.theme.designTokens.color.backgroundPrimary};
  &:hover {
    background-color: ${props =>
      props.theme.designTokens.color.backgroundTertiary};
  }
  && {
    margin: 0;
  }
`
// eslint-disable-next-line react/prop-types
const GroupList = ({ onClick, addRemoveFromGroupButtonState }) => (
  <GroupsListContainer data-testid="modify-group-membership-group-list">
    {/* eslint-disable-next-line react/prop-types  */}
    {addRemoveFromGroupButtonState.groupList.map(group => {
      return (
        <GroupListItem
          key={group.id}
          onClick={() => {
            onClick(group)
          }}
        >
          {group.name}
        </GroupListItem>
      )
    })}
  </GroupsListContainer>
)

const AddRemoveThingToFromGroupButtons = ({
  addRemoveFromGroupButtonState,
}) => {
  const [showAddToGroups, setShowAddToGroups] = useState(false)
  const [showRemoveFromGroups, setShowRemoveFromGroups] = useState(false)
  const toggleShowAddToGroups = () => {
    setShowAddToGroups(!showAddToGroups)
  }

  const closeGroupsLists = () => {
    setShowAddToGroups(false)
    setShowRemoveFromGroups(false)
  }
  const toggleShowRemoveFromGroups = () => {
    setShowRemoveFromGroups(!showRemoveFromGroups)
  }

  const handleAddToGroupClick = group => {
    addRemoveFromGroupButtonState.handleAddToGroup(group).then(() => {
      closeGroupsLists()
    })
  }

  const handleRemoveFromGroupClick = group => {
    addRemoveFromGroupButtonState.handleRemoveFromGroup(group).then(() => {
      closeGroupsLists()
    })
  }

  const numberOfSelectedThings = addRemoveFromGroupButtonState.selectedIds?.filter(
    rowId => rowId !== 'all',
  ).length
  const numberOfSelectedThingsText = numberOfSelectedThings
    ? // eslint-disable-next-line no-unneeded-ternary
      numberOfSelectedThings
    : ''

  const showButtons = !!numberOfSelectedThings

  return (
    showButtons && (
      <>
        <PositionedAncestor>
          <CustomButton
            onClick={toggleShowAddToGroups}
            isError={addRemoveFromGroupButtonState.isOnAddError}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Group to {numberOfSelectedThingsText}
            <FontAwesomeIcon icon={faCaretDown} />
          </CustomButton>
          {showAddToGroups && (
            <GroupList
              onClick={handleAddToGroupClick}
              addRemoveFromGroupButtonState={addRemoveFromGroupButtonState}
            />
          )}
        </PositionedAncestor>

        <PositionedAncestor>
          <CustomButton
            onClick={toggleShowRemoveFromGroups}
            isError={addRemoveFromGroupButtonState.isOnRemoveError}
          >
            <FontAwesomeIcon icon={faMinus} />
            Remove Group from {numberOfSelectedThingsText}
            <FontAwesomeIcon icon={faCaretDown} />
          </CustomButton>
          {showRemoveFromGroups && (
            <GroupList
              onClick={handleRemoveFromGroupClick}
              addRemoveFromGroupButtonState={addRemoveFromGroupButtonState}
            />
          )}
        </PositionedAncestor>
      </>
    )
  )
}

AddRemoveThingToFromGroupButtons.propTypes = {
  addRemoveFromGroupButtonState: useAddRemoveFromGroupButtonPropType.isRequired,
}

export default AddRemoveThingToFromGroupButtons
