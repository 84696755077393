import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { ButtonTertiary } from '../buttonStyledComponents'

const CopyIcon = () => <FontAwesomeIcon icon={faCopy} />

const CopyButton = props => {
  const { children } = props

  return (
    <ButtonTertiary type="button" {...props}>
      <CopyIcon />
      {children}
    </ButtonTertiary>
  )
}

CopyButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CopyButton
export { CopyIcon }
