import PropTypes from 'prop-types'
import React from 'react'
import { StyledBody } from '../appMiscStyledComponents'
import {
  StyledHeader,
  HeaderLeftSection,
  LogoContainer,
  Logo,
  AppTitle,
  BrandTextImage,
  PrimaryLayoutRow,
  StyledMain,
  LogoLink,
} from './layout.styled'
import { designTokens } from '../../theme'

const Layout = ({ children, menu, left }) => {
  return (
    <StyledBody full>
      <StyledHeader>
        <HeaderLeftSection>
          <LogoContainer>
            <LogoLink to="/" exact>
              <Logo src={designTokens.app.logoUrl} alt="Maxar" />
            </LogoLink>
          </LogoContainer>
          <AppTitle>
            <BrandTextImage
              src={designTokens.app.brandTextDarkUrl}
              alt="Spatial on Demand"
            />
          </AppTitle>
        </HeaderLeftSection>
        {menu}
      </StyledHeader>
      <PrimaryLayoutRow>
        {left}
        <StyledMain>{children}</StyledMain>
      </PrimaryLayoutRow>
    </StyledBody>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  left: PropTypes.node.isRequired,
  menu: PropTypes.node.isRequired,
}

export default Layout
