import { useEffect } from 'react'

const useInitializeCurrentOrganization = ({
  currentOrganization,
  getOrganization,
  openOrganizationManager,
  setCurrentOrganization,
  user,
}) => {
  useEffect(() => {
    const authorizationLevel = user?.authorization

    if (authorizationLevel) {
      const isNoOrganizationSelected = !currentOrganization?.id

      if (authorizationLevel === 'superuser' && isNoOrganizationSelected) {
        openOrganizationManager(false)
      }
      if (
        getOrganization &&
        (authorizationLevel === 'admin' || authorizationLevel === 'general')
      ) {
        const { organizationId } = user

        if (organizationId !== currentOrganization?.id) {
          getOrganization(organizationId)
            .then(organization => {
              setCurrentOrganization(organization)
            })
            .catch(() => {
              setCurrentOrganization({})
            })
        }
      }
    }

    /**  we have removed openOrganizationManager from the dep array because it causes
     * infinite renders (setting useState value to function/anon function/component + useEffect = weridness)
     * if this can be done a better, cleaner way archetecturally, this would be a
     * candidate for future refactors.
     * Other options were considered, but this was determined to be leave the
     * code easier to reason over */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrganization,
    setCurrentOrganization,
    user,
    getOrganization,
  ])
}

export default useInitializeCurrentOrganization
