import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import {
  SplashContainer,
  FinePrint,
} from '../../Splash/Splash.styled'
import LoginForm from './LoginForm'
import Splash from '../../Splash/Splash'

const Login = ({ onSubmit, errorMessage }) => {
  const [username, setUsername] = useState('')

  const handleOnSubmit = values => {
    onSubmit(values)
  }
  const usernameFromUrl = useParams().username

  useEffect(() => {
    if (usernameFromUrl) {
      setUsername(usernameFromUrl)
    }
  }, [usernameFromUrl])

  const year = new Date().getFullYear()

  return (
    <Splash
      extraContent={
        <FinePrint>
          <div>
            <span>&copy; {year} Copyright Maxar Technologies</span>
          </div>
        </FinePrint>
      }
    >
      <SplashContainer>
        <LoginForm
          onSubmit={handleOnSubmit}
          setUsername={setUsername}
          username={username}
          errorMessage={errorMessage}
        />
      </SplashContainer>
    </Splash>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

Login.defaultProps = {
  errorMessage: '',
}

export default Login
