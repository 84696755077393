import React from 'react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {
  TableMessageContainer,
  TableMessageFontAwesomeIcon,
} from '../appMiscStyledComponents'

/**
 * View for when the search filter returns nothing
 */

const NoFilterResults = () => {
  return (
    <TableMessageContainer data-testid="no-filter-results">
      <TableMessageFontAwesomeIcon icon={faSearch} />
      <div>
        <div>No results found.</div>
        <div>Please try another search.</div>
      </div>
    </TableMessageContainer>
  )
}

NoFilterResults.propTypes = {}

export default NoFilterResults
