import styled from 'styled-components/macro'

export const ErrorText = styled('p')`
  color: ${props => props.theme.designTokens.color.textError};
  margin: ${props => props.theme.designTokens.spacing.xxxsmall} 0;
`
export const ErrorMessage = styled.div`
  background-color: ${props => props.theme.designTokens.color.error};
  color: ${props => props.theme.designTokens.color.textLight};
  font-size: ${props => props.theme.designTokens.typography.xsmall};
  text-align: center;
  padding: ${props => props.theme.designTokens.spacing.xxxsmall}
    ${props => props.theme.designTokens.spacing.small};
`
