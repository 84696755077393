import styled from 'styled-components'
import { Column, RowSpaceBetween } from '../generic/layoutStyledComponents'

export const AuthTokenColumn = styled(Column)`
  flex-grow: 1;
`
export const AuthTokenRow = styled(RowSpaceBetween)`
  padding-left: 0;
  padding-right: 0;
`
