import React from 'react'
import PropTypes from 'prop-types'
import { StyledBody } from '../appMiscStyledComponents'
import { designTokens } from '../../theme'

import {
  BrandText,
  LeftPanel,
  Logo,
  MainPanelSection,
  Page,
} from './Splash.styled'
import { getBaseUrl } from '../../helpers'

/**
 * Splash for login, forgot password, new password, etc
 */

const Splash = props => {
  const { children, extraContent } = props

  return (
    <StyledBody>
      <Page>
        <LeftPanel>
          <MainPanelSection>
            <Logo>
              <img
                src={`${getBaseUrl()}/${designTokens.app.logoUrl}`}
                alt="Maxar"
              />
            </Logo>
            <BrandText>
              <img
                src={`${getBaseUrl()}/${designTokens.app.brandTextLightUrl}`}
                alt="Spatial on Demand"
              />
            </BrandText>
            {children}
          </MainPanelSection>
          {extraContent}
        </LeftPanel>
      </Page>
    </StyledBody>
  )
}

Splash.propTypes = {
  children: PropTypes.node.isRequired,
  extraContent: PropTypes.node,
}

Splash.defaultProps = {
  extraContent: undefined,
}

export default Splash
