/* eslint-disable react/prop-types */

import { useRowSelect, useSortBy, useTable } from 'react-table'
import React, { forwardRef, useRef, useEffect } from 'react'

const CheckboxReactTable = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
})

const useReactTableWithRestrictedFeatures = (
  options,
  showRestrictedFeatures,
) => {
  const addCheckBoxesToReactTableTable = hooks => {
    if (showRestrictedFeatures) {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <CheckboxReactTable
                {...getToggleAllRowsSelectedProps()}
                data-testid="select-all"
              />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <CheckboxReactTable {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  }

  return useTable(
    options,
    useSortBy,
    useRowSelect,
    addCheckBoxesToReactTableTable,
  )
}

export default useReactTableWithRestrictedFeatures
