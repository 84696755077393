import styled, { css } from 'styled-components'

import { getTextInputStylingString } from './TextInput/TextInput'
import downArrow from '../../icons/downArrow.svg'

export const Form = styled.form`
  & label {
    margin-top: ${props => props.theme.designTokens.spacing.medium};
  }
`
export const FormSectionLabel = styled('label')`
  font-weight: bold;
  & > * {
    margin-top: ${props => props.theme.designTokens.spacing.xsmall};
  }
`
export const Select = styled.select(
  props => css`
    ${getTextInputStylingString(props)}
    min-width: 0;
    appearance: none;
    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }
    background-image: url(${downArrow});
    background-repeat: no-repeat;
    background-position: right 0 top 50%;
    background-size: 1.7em;
    padding-right: 1.7em;
  `,
)
