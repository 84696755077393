import React, { useState } from 'react'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import { validationConfigs } from '../../dataServices'
import {
  groupPropType,
  groupServicePropType,
} from '../../dataServices/groupService'

import {
  RowBottomButtonErrorLeft,
  RowBottomButtonErrorRight,
  RowBottomButtons2Right1Left,
  RowContainer,
} from '../appMiscStyledComponents'
import {
  ButtonCaution,
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Form } from '../generic/formStyledComponents'
import { Row } from '../generic/layoutStyledComponents'
import { SidePanelTitle } from '../SidePanelStyledComponents'
import ConfirmDelete from '../ConfirmDelete'
import TextInput from '../generic/TextInput'

/**
 * Edit a group form
 */
const EditForm = ({
  deleteErrorMessage,
  // eslint-disable-next-line react/prop-types
  formik,
  onCancel,
  submitErrorMessage,
  toggleDeleteConfirmVisibility,
}) => (
  // eslint-disable-next-line react/prop-types
  <Form onSubmit={formik.handleSubmit}>
    <RowContainer>
      <Row>
        <SidePanelTitle>
          <FontAwesomeIcon icon={faUsers} />
          Edit Group
        </SidePanelTitle>
      </Row>
      <Row>
        <label htmlFor="name">
          Rename Group
          <TextInput
            id="name"
            // eslint-disable-next-line react/prop-types
            {...formik.getFieldProps('name')}
            // eslint-disable-next-line react/prop-types
            errorMessage={formik.errors.name}
          />
        </label>
      </Row>
    </RowContainer>
    <RowBottomButtons2Right1Left>
      <ButtonCaution
        type="button"
        onClick={toggleDeleteConfirmVisibility}
        isError={!!deleteErrorMessage}
      >
        Delete
      </ButtonCaution>
      <div>
        <ButtonTertiary type="button" onClick={onCancel}>
          Cancel
        </ButtonTertiary>
        <ButtonPrimary type="submit" isError={!!submitErrorMessage}>
          Save
        </ButtonPrimary>
      </div>
    </RowBottomButtons2Right1Left>
    {!!submitErrorMessage && (
      <RowBottomButtonErrorRight>
        <ErrorMessage>{submitErrorMessage}</ErrorMessage>
      </RowBottomButtonErrorRight>
    )}
    {!!deleteErrorMessage && (
      <RowBottomButtonErrorLeft>
        <ErrorMessage>{deleteErrorMessage}</ErrorMessage>
      </RowBottomButtonErrorLeft>
    )}
  </Form>
)

EditForm.propTypes = {
  deleteErrorMessage: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitErrorMessage: PropTypes.string.isRequired,
  toggleDeleteConfirmVisibility: PropTypes.func.isRequired,
}

const EditGroup = ({
  onCancel,
  group,
  onSaveSuccess,
  onDeleteSuccess,
  groupServiceInstance,
}) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('')
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const resetServerErrorMessages = () => {
    setSubmitErrorMessage('')
    setDeleteErrorMessage('')
  }

  const handleSubmit = values => {
    resetServerErrorMessages()
    const editedGroup = { name: values.name, id: group.id }

    groupServiceInstance
      .editGroup(editedGroup)
      .then(response => {
        onSaveSuccess(response.data)
      })
      .catch(() => {
        setSubmitErrorMessage('Unable to edit group. Please try again.')
      })
  }

  const toggleDeleteConfirmVisibility = () => {
    setShowDeleteConfirm(!showDeleteConfirm)
  }

  const handleDeleteConfirmed = () => {
    resetServerErrorMessages()
    groupServiceInstance
      .deleteGroup(group)
      .then(() => {
        onDeleteSuccess(group)
      })
      .catch(() =>
        setDeleteErrorMessage('Unable to delete group. Please try again.'),
      )
  }
  const formik = useFormik({
    initialValues: {
      name: group.name,
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationConfigs.group,
  })

  return showDeleteConfirm ? (
    <ConfirmDelete
      deleteObjectName={group.name}
      notModal
      onCancel={toggleDeleteConfirmVisibility}
      onConfirm={handleDeleteConfirmed}
      errorMessage={deleteErrorMessage}
    />
  ) : (
    <EditForm
      {...{
        deleteErrorMessage,
        formik,
        onCancel,
        submitErrorMessage,
        toggleDeleteConfirmVisibility,
      }}
    />
  )
}

EditGroup.propTypes = {
  group: groupPropType.isRequired,
  groupServiceInstance: groupServicePropType.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
}

export default EditGroup
