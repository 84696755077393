import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'

import { copyUrl } from '../../../helpers'
import HiddenText from '../../generic/HiddenText'
import CopyButton, { CopyIcon } from '../../generic/CopyButton/CopyButton'
import { H1 } from '../../generic/TextStyledComponents'

/**
 * Describe your component
 */
const StyledHiddenText = styled(HiddenText)`
  margin-bottom: ${props => props.theme.designTokens.spacing.medium};
`

const AuthToken = ({ token }) => {
  const [isTokenvisible, setIsTokenVisible] = useState(false)
  const toggleIsTokenVisible = () => {
    setIsTokenVisible(!isTokenvisible)
  }

  const tokenRef = useRef()

  const handleCopyToken = () => {
    copyUrl(tokenRef.current)
    toast(
      <>
        <CopyIcon /> Auth token copied to clipboard
      </>,
    )
  }

  const CustomCopyButton = styled(CopyButton)`
    align-self: flex-start;
  `

  return (
    <>
      <H1 data-testid="page-title">Your Auth Token</H1>

      <StyledHiddenText
        ref={tokenRef}
        text={token}
        buttonShowText="Show Token"
        buttonHideText="Hide Token"
        onToggle={toggleIsTokenVisible}
      />
      {isTokenvisible && (
        <CustomCopyButton onClick={handleCopyToken}>
          Copy Auth Token
        </CustomCopyButton>
      )}
    </>
  )
}

AuthToken.propTypes = { token: PropTypes.string.isRequired }

export default AuthToken
