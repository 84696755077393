import styled from 'styled-components'
import ButtonError from './ButtonError/ButtonError'

export const ButtonPrimary = styled(ButtonError)`
  background: ${props =>
    props.theme.designTokens.button.primaryBackgroundColor};
  color: ${props => props.theme.designTokens.color.text};
  border: none;
  &:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.primaryBackgroundColor}`};
  }
`
export const ButtonSecondary = styled(ButtonError)`
  background: ${props =>
    props.theme.designTokens.button.secondaryBackgroundColor};
  color: ${props => props.theme.designTokens.color.textLight};
  &:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.secondaryBackgroundColor}`};
  }
`
export const ButtonTertiary = styled(ButtonError)`
  background: ${props =>
    props.theme.designTokens.button.tertiaryBackgroundColor};
  color: ${props => props.theme.designTokens.color.textLight};
  &:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.tertiaryBackgroundColor}`};
  }
`

export const ButtonQuaternary = styled(ButtonError)`
  background: ${props =>
    props.theme.designTokens.button.quaternatyBackgroundColor};
  color: ${props => props.theme.designTokens.color.textLight};
  &:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.quaternatyBackgroundColor}`};
  }
`
export const ButtonCaution = styled(ButtonError)`
  background: ${props =>
    props.theme.designTokens.button.cautionBackgroundColor};
  color: ${props => props.theme.designTokens.color.textLight};
  &:hover {
    box-shadow: ${props =>
      `0 0 0 ${props.theme.designTokens.spacing.xxsmall} ${props.theme.designTokens.button.cautionBackgroundColor}`};
  }
`
export const LinkButton = styled('button')`
  font-size: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  overflow: visible; /* IE hack */
  width: auto; /* IE hack */
`
