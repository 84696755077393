import PropTypes from 'prop-types'

const passwordService = {
  newPassword(password) {
    return new Promise(resolve => {
      resolve(password)
    })
  },
  forgotPassword(usernameOrEmail) {
    return new Promise(resolve => {
      resolve(usernameOrEmail)
    })
  },
}

export default passwordService
export const passwordServiceProptype = PropTypes.shape({
  newPassword: PropTypes.func,
  forgotPassword: PropTypes.func,
})
