import styled from 'styled-components'

export const TinyText = styled.div`
  font-size: ${props => props.theme.designTokens.typography.xsmall};
`
export const H1 = styled('h1')`
  font-size: ${props => props.theme.designTokens.typography.h1Size};
  text-transform: ${props =>
    props.theme.designTokens.typography.h1TextTransform};
`
export const H2 = styled('h2')`
  font-size: ${props => props.theme.designTokens.typography.h2Size};
  font-weight: ${props => props.theme.designTokens.typography.h2Weight};
  margin: ${props => props.theme.designTokens.typography.h2Margin};
`
export const H4 = styled('h4')`
  font-size: ${props => props.theme.designTokens.typography.h4Size};
  font-weight: ${props => props.theme.designTokens.typography.h4Weight};
  margin: ${props => props.theme.designTokens.typography.h4Margin};
`
export const Link = styled('a')`
  color: ${prop => prop.theme.designTokens.color.brandPrimary};
`
export const Code = styled.div`
  font-family: ${props => props.theme.designTokens.typography.fontFamilyCode};
`
export const P = styled.p`
  font-size: ${props => props.theme.designTokens.typography.sizeDefault};
  margin: 0 0 ${props => props.theme.designTokens.spacing.medium};
`
