import React, { useState } from 'react'
import { Modal, defaultModalPosition } from '../Modal'

const useModal = () => {
  const [modalPosition, setModalPosition] = useState(defaultModalPosition)
  const [modalContents, setModalContents] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allowModalDismissal, setAllowModalDismissal] = useState(true)

  const openModal = (contents, allowDismissal) => {
    setModalContents(contents)
    setIsModalOpen(true)
    setAllowModalDismissal(allowDismissal)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setModalPosition(defaultModalPosition)
  }

  return {
    handleCloseModal,
    Modal: () => (
      <Modal position={modalPosition} onClose={handleCloseModal} allowDismissal={allowModalDismissal}>
        {modalContents}
      </Modal>
    ),
    setModalPosition,
    isModalOpen,
    openModal,
  }
}

export default useModal
