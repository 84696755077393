import { useCallback, useEffect, useRef, useState } from 'react'

import {
  DataService,
  GroupService,
  OrganizationService,
  UserService,
  passwordService as _passwordService,
} from '../../dataServices'

const useAuthenticatedState = authenticationService => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState({})
  const [authorizationLevel, setAuthorizationLevel] = useState('unauthorized')
  const [authToken, setAuthToken] = useState('')
  const [
    isAuthenticationInitialized,
    setIsAuthenticationInitialized,
  ] = useState(false)

  const dataService = useRef()
  const groupService = useRef()
  const organizationService = useRef()
  const userService = useRef()
  const passwordService = useRef()

  const setAuthenticatedState = useCallback(
    account => {
      userService.current = new UserService(authenticationService)
      groupService.current = new GroupService(authenticationService)
      dataService.current = new DataService(authenticationService)
      organizationService.current = new OrganizationService(
        authenticationService,
      )

      setUser(account)
      setAuthorizationLevel(account.authorization)
      setAuthToken(account.authToken)

      setIsLoggedIn(true)
    },
    [authenticationService],
  )

  useEffect(() => {
    let isMounted = true

    passwordService.current = _passwordService

    authenticationService
      .checkAuthentication()
      .then(account => {
        if (isMounted) {
          setAuthenticatedState(account)
        }
        setIsAuthenticationInitialized(true)
      })

      .catch(reason => {
        // eslint-disable-next-line no-console
        console.log(reason)
        setIsAuthenticationInitialized(true)
      })

    return () => {
      // prevents memory leaks and state being updated after unmount
      isMounted = false
    }
  }, [authenticationService, setAuthenticatedState])

  return {
    authorizationLevel,
    authToken,
    dataService: dataService.current,
    groupService: groupService.current,
    isAuthenticationInitialized,
    isLoggedIn,
    organizationService: organizationService.current,
    passwordService: passwordService.current,
    setAuthenticatedState,
    setIsLoggedIn,
    user,
    userService: userService.current
  }
}

export default useAuthenticatedState
