import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { LinkButton } from '../buttonStyledComponents'
import IconButton from '../IconButton'

/**
 * Obscured text with a button to toggle if it is visible or not
 */
const Styles = styled.div`
  display: flex;
  align-items: top;
`
const StyledLinkButton = styled(LinkButton)`
  text-decoration: underline;
  color: ${prop => prop.theme.designTokens.color.textSecondary};
`
const StyledText = styled.div`
  margin-right: ${props => props.theme.designTokens.spacing.small};
  font-family: ${props =>
    props.isTextVisible
      ? props.theme.designTokens.typography.fontFamilyCode
      : 'inherit'};
`

const HiddenText = React.forwardRef((props, ref) => {
  const {
    buttonHideText,
    buttonShowText,
    className,
    hideTooltip,
    onToggle,
    showTooltip,
    text,
  } = props
  const [isTextVisible, setIsTextvisible] = useState(false)

  const toggleIsTextvisible = () => {
    onToggle()
    setIsTextvisible(!isTextVisible)
  }

  const ExplicitLinkButton = () => (
    <StyledLinkButton onClick={toggleIsTextvisible}>
      {isTextVisible ? buttonHideText : buttonShowText}
    </StyledLinkButton>
  )

  const EyeButton = () => (
    <IconButton
      onClick={toggleIsTextvisible}
      icon={<FontAwesomeIcon icon={isTextVisible ? faEyeSlash : faEye} />}
      ariaLabel={isTextVisible ? hideTooltip : showTooltip}
      tooltip={isTextVisible ? hideTooltip : showTooltip}
    />
  )

  const ToggleButton = () =>
    buttonShowText && buttonHideText ? <ExplicitLinkButton /> : <EyeButton />

  return (
    <Styles className={className}>
      <StyledText ref={ref} isTextVisible={isTextVisible}>
        {isTextVisible ? (
          <>{text}</>
        ) : (
          // using unicode here to get something that somewhat
          // looks like vertical alignment with asterix, which
          // seems to default to superscript alignment
          <>{String.fromCharCode(8727).repeat(14)}</>
        )}
      </StyledText>
      <ToggleButton />
    </Styles>
  )
})

HiddenText.propTypes = {
  buttonHideText: PropTypes.string,
  buttonShowText: PropTypes.string,
  className: PropTypes.string,
  hideTooltip: PropTypes.string,
  onToggle: PropTypes.func,
  showTooltip: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
}

HiddenText.defaultProps = {
  buttonShowText: undefined,
  buttonHideText: undefined,
  hideTooltip: 'Hide',
  showTooltip: 'Show',
  className: undefined,
  onToggle: () => {},
}

export default HiddenText
