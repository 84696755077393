import styled from 'styled-components/macro'

import { designTokens } from '../../theme'
import { RowSpaceBetween } from '../generic/layoutStyledComponents'

export const NoDataContainer = styled.div`
  margin: ${designTokens.spacing.xxxlarge};
`

export const StyledRowSpaceBetween = styled(RowSpaceBetween)`
  align-items: flex-end;
`
