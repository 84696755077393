import React from 'react'
import PropTypes from 'prop-types'
import { Layer } from 'grommet'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { ErrorMessage } from '../generic/ErrorStyledComponents'

import { designTokens } from '../../theme/theme.tokens'
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../generic/buttonStyledComponents'
import { CloseButton } from '../SidePanelStyledComponents'
/**
 * Describe your component
 */

const ContentsContainer = styled('div')`
  min-width: 500px;
  display: flex;
  flex-direction: column;
  padding: ${designTokens.spacing.large};
`
const Row = styled('div')`
  display: flex;
  flex-direction: row;
`
const TextRow = styled(Row)`
  justify-content: flex-start;
  align-items: center;
`
const ButtonRow = styled(Row)`
  justify-content: flex-end;
  margin-top: ${designTokens.spacing.large};
  & ${ButtonPrimary} {
    margin-left: ${designTokens.spacing.large};
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: ${designTokens.typography.h3Size};
  margin-right: ${designTokens.spacing.small};
`

const ErrorRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.designTokens.spacing.xsmall};
`

const ConfirmDelete = ({
  deleteObjectName,
  onCancel,
  onConfirm,
  notModal,
  className,
  errorMessage,
}) => {
  const Contents = () => (
    <ContentsContainer className={className}>
      <TextRow>
        <StyledFontAwesomeIcon icon={faTrash} />
        {`Are you sure you want to delete ${deleteObjectName}?`}
      </TextRow>

      <ButtonRow>
        <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={onConfirm} isError={!!errorMessage}>
          Yes, Delete
        </ButtonPrimary>
      </ButtonRow>
      {errorMessage && (
        <ErrorRow>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorRow>
      )}
    </ContentsContainer>
  )

  return notModal ? (
    <Contents />
  ) : (
    <Layer modal full={false} onClickOutside={onCancel} onEsc={onCancel}>
      <CloseButton onClose={onCancel} />
      <Contents />
    </Layer>
  )
}

ConfirmDelete.propTypes = {
  deleteObjectName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  notModal: PropTypes.bool, // sorry for the double negative. Tight timelines and this means less refactoring.
  className: PropTypes.string,
  errorMessage: PropTypes.string,
}

ConfirmDelete.defaultProps = {
  notModal: false,
  className: '',
  errorMessage: undefined,
}

export default ConfirmDelete
