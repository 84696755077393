import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer } from 'react'

import { groupServicePropType } from '../../dataServices/groupService'
import StatusIndicator from '../generic/StatusIndicator'
import { FormView } from './FormView'
import { organizationPropType } from '../../dataServices/organizationService'
import { userServicePropType } from '../../dataServices'
import { validateRegex, validationMessage } from '../../helpers/passwordRequirements'

/**
 * New Account Form
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'receivedGroupInfo': {
      const groups = action.payload

      return {
        ...state,
        hasApiReturned: true,
        availableGroups: groups,
        initiallyCheckedGroups: groups
          .map(group => group.name),
      }
    }
    case 'groupServerReject':
      return {
        ...state,
        hasApiReturned: true,
        groupServerError:
          'Something went wrong in fetching groups from the server.',
      }
    case 'newUserServerError':
      return {
        ...state,
        newUserServerError: 'Unable to create account. Please try again.',
      }
    default:
      throw new Error()
  }
}

const NewUser = ({
  userService,
  onClose,
  onCreateNewAccount,
  groupList,
  currentOrganization
}) => {
  const [state, dispatch] = useReducer(reducer, {
    availableGroups: [],
    initiallyCheckedGroups: [],
    hasApiReturned: false,
  })

  useEffect(() => {
    dispatch({ type: 'receivedGroupInfo', payload: groupList })
  }, [groupList])

  const handleSubmit = (values, resetFunction) => {
    const { groups, password, passwordConfirm, role, username, nad27Preferred } = values

    const newUserInfo = {
      role,
      organization_id: currentOrganization.id,
      active: true,
      username,
      nad_27_preferred: nad27Preferred,
      raw_password: password,
      raw_password_repeat: passwordConfirm
    }

    userService
      .addUser(newUserInfo)
      .then((newUserResponse) => {
        const findGroupByName = name => state.availableGroups.find(group => group.name === name)
        const groupIds = groups.map(groupName => findGroupByName(groupName).id)

        return userService.updateGroupMembership(newUserResponse.id, groupIds)
      })
      .then(() => {
        resetFunction()
        onCreateNewAccount(values)
      })
      .catch(() => {
        dispatch({ type: 'newUserServerError' })
      })
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
      username: '',
      role: 'general',
      groups: state.initiallyCheckedGroups,
      nad27Preferred: false
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm)
    },
    validationSchema: Yup.object({
      isServerAccount: Yup.boolean(),
      username: Yup.string()
        .required('Please enter a user name.')
        .matches(/^[a-zA-Z0-9-_@+ ]+$/, {
          message: 'User names may contain @, _, -, +, letters, numbers, and spaces.',
        }),
      password: Yup.string()
        .required('Please enter the user\'s new password.')
        .matches(
          validateRegex,
          validationMessage
        ),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      role: Yup.string().required('Please select a role type'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true, // so you can get initial values from state
  })

  return state.hasApiReturned ? (
    <FormView onClose={onClose} formik={formik} state={state} />
  ) : (
    <StatusIndicator />
  )
}

NewUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreateNewAccount: PropTypes.func.isRequired,
  userService: userServicePropType.isRequired,
  groupList: PropTypes.arrayOf(groupServicePropType).isRequired,
  currentOrganization: organizationPropType.isRequired,
}

export default NewUser
