import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { ErrorMessage } from '../ErrorStyledComponents'

/**
 * Describe your component
 */

export const getTextInputStylingString = props => `
  padding: 0 ${props.theme.designTokens.spacing.small};
  border: ${props.theme.designTokens.border.input};
  box-sizing: border-box;
  height: ${props.theme.designTokens.form.inputHeight};
  min-width: ${props.theme.designTokens.form.inputWidth};
  font-size: ${props.theme.designTokens.typography.sizeDefault};
  &:focus {
    outline: ${props.theme.designTokens.border.inputFocusOutline};
  }
`
export const $TextInputContainer = styled.div`
  ${props => getTextInputStylingString(props)}
  display: flex;
  justify-content: space-between;
  & svg {
    height: calc(${props => props.theme.designTokens.form.inputHeight} - 2px);
  }
  & input {
    flex-grow: 1;
    margin-right: ${props => props.theme.designTokens.spacing.small};
    border: none;
    font-size: inherit;
    & :focus {
      outline: none;
    }
  }

  & :focus-within {
    outline: ${props => props.theme.designTokens.border.inputFocusOutline};
    outline-offset: -1px;
  }
`

const TextInputContainerError = styled($TextInputContainer)`
  ${props => getTextInputStylingString(props)}
   border: ${props => props.theme.designTokens.app.borderError};
   
   
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const TextInput = props => {
  const { errorMessage, icon, className, value, ...rest } = props

  return (
    <Container className={className}>
      {errorMessage ? (
        <>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <TextInputContainerError>
            <input type="text" value={value} {...rest} />
            {icon}
          </TextInputContainerError>

          <ErrorMessage>{errorMessage}</ErrorMessage>
        </>
      ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <$TextInputContainer>
          <input type="text" value={value} {...rest} />
          {icon}
        </$TextInputContainer>
      )}
    </Container>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.string,
}

TextInput.defaultProps = {
  className: '',
  errorMessage: '',
  icon: undefined,
  value: '',
}

export default TextInput
