import React, { useState, useEffect } from 'react'
import { faCalculator, faCog, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  NoDataContainer,
  StyledRowSpaceBetween,
} from './OrganizationManager.styled'
import NoFilterResults from '../NoFilterResults'
import NoItemsFromApi from '../NoItemsFromApi'
import FilterBar from '../generic/FilterBar'
import { Table, Td, Th, TrSelectable } from '../generic/TableAndPagination'
import IconButton from '../generic/IconButton'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import { Row } from '../generic/layoutStyledComponents'
import { dataServicePropType } from '../../dataServices'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'
import {
  RowBottomButtonErrorRight,
  RowBottomButtons,
} from '../appMiscStyledComponents'

const CustomTh = styled(Th)`
  width: 100px;
`

const prepareAdminsForDisplay = admins =>
  admins
    ?.map(admin => admin.username)
    .sort()
    .join(', ')
const TableView = ({
  onClosePanel,
  onNewOrganization,
  onOrganizationEdit,
  onOrganizationSelect,
  tableDataAndState,
  allowDismissal,
  onOrganizationManageLayers,
  onManageAllLayers,
  layerService
}) => {
  const {
    tableData,
    doesApiReturnData,
    unfilteredTableData,
    setTableData,
  } = tableDataAndState

  const [organizationToBeSelected, setOrganizationToBeSelected] = useState()

  const [showUnsavedChangesBadge, setShowUnsavedChangesBadge] = useState(false)

  useEffect(() => {
    layerService.getLayerConfigStatus()
      .then(status => {
        if (status === 'pending') {
          setShowUnsavedChangesBadge(true)
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [
    selectOrganizationErrorMessage,
    setSelectOrganizationErrorMessage,
  ] = useState('')

  const resetFilter = () => {
    setTableData(unfilteredTableData.current)
  }

  const organizationFilter = filterQuery => {
    const lowerCaseQuery = filterQuery.toLowerCase()
    const filterFunction = organization => {
      return (
        organization.name?.toLowerCase()?.includes(lowerCaseQuery) ||
        prepareAdminsForDisplay(organization.admins)?.includes(
          lowerCaseQuery,
        ) ||
        organization.user_count?.toString()?.includes(lowerCaseQuery)
      )
    }
    const filteredOrganizations = unfilteredTableData.current.filter(
      filterFunction,
    )

    setTableData(filteredOrganizations)
  }

  const handleEditClick = id => {
    onOrganizationEdit(id)
  }

  const handleManageLayersClick = id => {
    onOrganizationManageLayers(id)
  }

  const handleSelectOrganizationClick = () => {
    if (organizationToBeSelected) {
      onOrganizationSelect(organizationToBeSelected)
      onClosePanel()
    } else {
      setSelectOrganizationErrorMessage(
        'Please select an organization and try again.',
      )
    }
  }

  const NoData = () =>
    doesApiReturnData ? (
      <NoFilterResults />
    ) : (
      <NoItemsFromApi itemType="organization" onAddItem={onNewOrganization} />
    )

  const FilterableTable = () =>
    tableData.length ? (
      <Row>
        <Table>
          <thead>
            <TrSelectable>
              <Th>Organization Name</Th>
              <Th>Admin</Th>
              <Th>Users</Th>
              <Th>Manage&nbsp;Layers</Th>
              <CustomTh />
            </TrSelectable>
          </thead>
          <tbody>
            {tableData.map(organization => {
              const handleRowSelect = () => {
                setSelectOrganizationErrorMessage('')
                setOrganizationToBeSelected(organization)
              }

              return (
                <TrSelectable
                  key={organization.id}
                  selected={organization.id === organizationToBeSelected?.id}
                >
                  <Td onClick={handleRowSelect}>{organization.name}</Td>
                  <Td onClick={handleRowSelect}>
                    {prepareAdminsForDisplay(organization.admins)}
                  </Td>
                  <Td align="right" onClick={handleRowSelect}>
                    {organization.user_count}
                  </Td>
                  <Td>
                    <ButtonTertiary
                      onClick={() => {
                        handleManageLayersClick(organization.id)
                      }}>
                      Manage&nbsp;Layers
                    </ButtonTertiary>
                  </Td>
                  <Td>
                    <IconButton
                      icon={<FontAwesomeIcon icon={faCog} />}
                      tooltip="Modify"
                      ariaLabel="Modify"
                      onClick={() => {
                        handleEditClick(organization.id)
                      }}
                    />
                  </Td>
                </TrSelectable>
              )
            })}
          </tbody>
        </Table>
      </Row>
    ) : (
      <NoDataContainer>
        <NoData />
      </NoDataContainer>
    )

  return (
    <>
      {!!unfilteredTableData.current.length && (
        <StyledRowSpaceBetween>
          <FilterBar onReset={resetFilter} onFilter={organizationFilter} />
          <ButtonPrimary
            onClick={onManageAllLayers}
            errorMessage={showUnsavedChangesBadge ? "Unapplied changes" : undefined}
          >
            <FontAwesomeIcon icon={faLayerGroup} />
            Manage all available layers
          </ButtonPrimary>
          <ButtonPrimary onClick={onNewOrganization}>
            <FontAwesomeIcon icon={faCalculator} />
            New Organization
          </ButtonPrimary>
        </StyledRowSpaceBetween>
      )}

      <FilterableTable />

      <RowBottomButtons>
        {allowDismissal && <ButtonTertiary onClick={onClosePanel}>Cancel</ButtonTertiary>}
        <ButtonPrimary
          isError={!!selectOrganizationErrorMessage}
          onClick={handleSelectOrganizationClick}
        >
          Select Organization
        </ButtonPrimary>
      </RowBottomButtons>
      {!!selectOrganizationErrorMessage && (
        <RowBottomButtonErrorRight>
          <ErrorMessage>{selectOrganizationErrorMessage}</ErrorMessage>
        </RowBottomButtonErrorRight>
      )}
    </>
  )
}

TableView.propTypes = {
  onClosePanel: PropTypes.func,
  onNewOrganization: PropTypes.func.isRequired,
  onOrganizationEdit: PropTypes.func.isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  tableDataAndState: PropTypes.shape({
    doesApiReturnData: PropTypes.bool,
    setTableData: PropTypes.func,
    tableData: PropTypes.arrayOf(PropTypes.object),
    unfilteredTableData: PropTypes.shape({
      current: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  allowDismissal: PropTypes.bool,
  onOrganizationManageLayers: PropTypes.func.isRequired,
  onManageAllLayers: PropTypes.func.isRequired,
  layerService: dataServicePropType.isRequired,
}

TableView.defaultProps = {
  onClosePanel: () => {},
  allowDismissal: true
}

export default TableView
