import { Header, Main } from 'grommet'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components/macro'

import { designTokens } from '../../theme'

const spacingTokens = designTokens.spacing

export const HeaderLeftSection = styled('div')`
  display: flex;

  & > * {
    display: flex;
  }
`

export const PrimaryLayoutRow = styled.div`
  display: flex;
  flex-grow: 1;
`

// headerLeftRight padding is used to calculate the width of the LogoContainer
// component which results in a properly aligned app title.
export const headerLeftRightPadding = designTokens.spacing.large
export const headerTopBottomPadding = designTokens.spacing.large

export const StyledHeader = styled(Header)`
  border-bottom: ${designTokens.border.primary};
  padding: ${headerTopBottomPadding} ${headerLeftRightPadding};
  /* hack to address a padding bug in Grommet Menu's  (whose padding is 12px)*/
  padding-top: calc(${headerTopBottomPadding} - 12px);
  padding-bottom: calc(${headerTopBottomPadding} - 12px);
  padding-right: calc(${headerLeftRightPadding} - 12px);
`
export const LogoContainer = styled('div')`
  width: calc(${designTokens.app.navWidth} - ${headerLeftRightPadding});
  box-sizing: border-box;
`
export const AppTitle = styled('div')`
  margin-left: ${spacingTokens.xlarge};
  width: 400px;
`

export const StyledMain = styled(Main)`
  background-color: ${designTokens.color.backgroundPrimary};
  padding: ${spacingTokens.xlarge};
  width: calc(100vw - ${designTokens.app.navWidth});
`

export const Logo = styled('img')`
  width: ${designTokens.app.logoHeaderWidth};
  height: ${designTokens.app.logoHeaderHeight};
`
export const LogoLink = styled(NavLink)`
  display: flex;
  justify-content: column;
`
export const BrandTextImage = styled('img')`
  height: ${designTokens.app.logoHeaderHeight};
`
