import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import React from 'react'
import { ErrorMessage } from '../generic/ErrorStyledComponents'
import TextInput from '../generic/TextInput'
import { organizationPropType } from '../../dataServices/organizationService'
import { Form, FormSectionLabel } from '../generic/formStyledComponents'
import { Column, Row } from '../generic/layoutStyledComponents'
import {
  RowBottomButtonErrorRight,
  RowBottomButtons,
} from '../appMiscStyledComponents'
import {
  ButtonPrimary,
  ButtonTertiary,
} from '../generic/buttonStyledComponents'

const ModifyOrgView = ({
  organization,
  onEditOrganization,
  serverError,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {
      orgName: organization.name,
      mapHitQuota: !organization.maphit_quota ? '' : organization.maphit_quota.toString(),
      hostedDataQuota: !organization.hosted_data_quota ? '' : organization.hosted_data_quota.toString()
    },
    onSubmit: values => {
      const newOrg = {
        ...organization,
        name: values.orgName,
        maphit_quota: values.mapHitQuota,
        hosted_data_quota: values.hostedDataQuota
      }

      onEditOrganization(newOrg)
    },
    validationSchema: Yup.object({
      orgName: Yup.string()
        .trim()
        .required('Please enter an organization name.')
        .matches(
          /^[a-zA-Z0-9-_ ]+$/,
          'Organization names may contain _, -, letters, numbers, and spaces.',
        ),
      mapHitQuota: Yup.number()
          .min(-1)
          .integer()
          .nullable(),
      hostedDataQuota: Yup.number()
          .min(0)
          .max(100000)
          .integer()
          .nullable(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Column>
          <FormSectionLabel htmlFor="orgName">
            Organization Name
            <TextInput
              name="orgName"
              errorMessage={formik.errors.orgName}
              {...formik.getFieldProps('orgName')}
            />
          </FormSectionLabel>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormSectionLabel htmlFor="mapHitQuota">
            Map Hit Quota
            <TextInput
                name="mapHitQuota"
                errorMessage={formik.errors.mapHitQuota}
                {...formik.getFieldProps('mapHitQuota')}
            />
          </FormSectionLabel>
        </Column>
      </Row>
      <Row>
        <Column>
          <FormSectionLabel htmlFor="hostedDataQuota">
            Hosted Data Quota
            <TextInput
                name="hostedDataQuota"
                errorMessage={formik.errors.hostedDataQuota}
                {...formik.getFieldProps('hostedDataQuota')}
            />
          </FormSectionLabel>
        </Column>
      </Row>
      <RowBottomButtons>
        <ButtonTertiary type="button" onClick={onClose}>
          Cancel
        </ButtonTertiary>
        <ButtonPrimary type="submit" isError={!!serverError}>
          Update Organization
        </ButtonPrimary>
      </RowBottomButtons>
      {serverError && (
        <RowBottomButtonErrorRight>
          <ErrorMessage>{serverError}</ErrorMessage>
        </RowBottomButtonErrorRight>
      )}
    </Form>
  )
}

ModifyOrgView.propTypes = {
  organization: organizationPropType.isRequired,
  onEditOrganization: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  serverError: PropTypes.string.isRequired,
}

export default ModifyOrgView
