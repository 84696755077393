export const getServerBaseUrl = () =>
  process.env.REACT_APP_LOGIN_SERVICE_URL || 'https://spatialondemand.maxar.com/'

export const getRandomInt = upToNumber => {
  // Zero up to, but not including upToNumber
  return Math.floor(Math.random() * Math.floor(upToNumber))
}

export const compareByPropertyName = propertyName => {
  // eslint-disable-next-line func-names
  return function(a, b) {
    return a[propertyName].localeCompare(b[propertyName])
  }
}

export const copyUrl = node => {
  const range = document.createRange()
  const selectedText = window.getSelection()

  range.selectNode(node)
  selectedText.empty()
  selectedText.addRange(range) // to select text
  document.execCommand('copy')
  selectedText.empty()
}

export const copyText = text => {
  const node = document.createElement('div')

  node.textContent = text
  document.body.append(node)
  copyUrl(node)
  node.remove()
}

export const getIP = async () => {
  const response = await fetch('http://api.ipify.org/?format=json')
  const data = await response.json()

  return data
}

export const getBaseUrl = () =>
  `${window.location.protocol}//${window.location.host}`

export const sortByNameCaseInsensitive = objectList => {
  return objectList.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  })
}
