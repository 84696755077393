import * as Yup from 'yup'

const group = Yup.object({
  name: Yup.string()
    .trim()
    .required('Please enter a group name.'),
})
const validationConfigs = { group }

export { validationConfigs }
