import { designTokens } from './theme.tokens'
import appTokens from './app.tokens'

const mergedDesignTokens = { ...designTokens, app: { ...appTokens } }

const grommetTheme = {
  global: {
    focus: { border: { color: 'none' } },
    font: {
      family: mergedDesignTokens.typography.fontFamily,
    },
    colors: {
      brand: mergedDesignTokens.color.brandPrimary,
      border: {
        dark: mergedDesignTokens.border.colorPrimary,
        light: mergedDesignTokens.border.colorPrimary,
      },
      borderSize: mergedDesignTokens.border.width,
      navBackground: mergedDesignTokens.app.navBackgroundColor,
      navBackgroundGroup: mergedDesignTokens.app.navBackGroundColorDarker,
    },
  },
  button: { extend: `border-radius: 0` },
  formField: {
    border: {
      color: 'none',
      error: { color: { dark: 'none', light: 'none' } },
    },
    label: {
      margin: {
        horizontal: 'none',
        vertical: mergedDesignTokens.spacing.small,
      },
      weight: 'bold',
    },
  },
}

const grommetThemeWithDesignTokens = {
  designTokens: mergedDesignTokens,
  ...grommetTheme,
}

export { mergedDesignTokens as designTokens, grommetThemeWithDesignTokens }
