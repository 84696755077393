import React from 'react'
import { Th, ThForCheckboxes, Tr } from './TableAndPagination.styled'

/** This function is specifically for react-table tables */
const getReactTableHeaders = (
  headerGroups,
  options = { showExtraHeaderForButtonsSpacing: true },
) =>
  headerGroups.map(headerGroup => (
    <Tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => {
        return column.id === 'selection' ? (
          <ThForCheckboxes {...column.getHeaderProps()}>
            {column.render('Header')}
          </ThForCheckboxes>
        ) : (
          <Th {...column.getHeaderProps(column.getSortByToggleProps())} align={column.align}>
            {column.render('Header')}
            {/* Add a sort direction indicator */}
            <span>
                {/* eslint-disable-next-line no-nested-ternary */}
                {column.isSorted
                  ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                  : ''}
            </span>
          </Th>
        )
      })}
      {options.showExtraHeaderForButtonsSpacing && <Th />}
    </Tr>
  ))

export default getReactTableHeaders
