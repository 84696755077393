import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { groupServicePropType } from '../../dataServices'

const useGroupList = (currentOrganization, groupService, shouldFetchGroups) => {
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    if (shouldFetchGroups) {
      groupService
        .getGroups(currentOrganization)
        .then(response => {
          setGroupList(response)
        })
        .catch(() => setGroupList([]))
    }
  }, [groupService, currentOrganization, shouldFetchGroups])

  return groupList
}

const useResetErrorWhenSelectionChanges = (
  resetGroupAddRemoveErrors,
  selectedIds,
) =>
  useEffect(() => {
    resetGroupAddRemoveErrors()
  }, [selectedIds, resetGroupAddRemoveErrors])

const useAddRemoveFromGroupButtonState = ({
  selectedIds,
  groupService,
  currentOrganization,
  onAddedToGroup,
  onRemovedFromGroup,
  nameOfThingsToBeAddedRemoved,
  shouldFetchGroups,
  addExecutorReturningPromise,
  removeExecutorReturningPromise
}) => {
  const [isOnAddError, setIsOnAddError] = useState(false)
  const [isOnRemoveError, setIsOnRemoveError] = useState(false)
  const [addRemoveErrorMessage, setAddRemoveErrorMessage] = useState()
  const resetGroupAddRemoveErrors = useCallback(() => {
    setIsOnRemoveError(false)
    setIsOnAddError(false)
    setAddRemoveErrorMessage('')
  }, [setIsOnRemoveError, setIsOnAddError, setAddRemoveErrorMessage])

  useResetErrorWhenSelectionChanges(resetGroupAddRemoveErrors, selectedIds)

  const handleAddToGroup = group => {
    resetGroupAddRemoveErrors()

    return addExecutorReturningPromise(group.id, selectedIds)
      .then(() => {
        onAddedToGroup(nameOfThingsToBeAddedRemoved, group.name)
      })
      .catch(() => {
        setIsOnAddError(true)
        setAddRemoveErrorMessage(
          `Unable to add selected ${nameOfThingsToBeAddedRemoved}. Please try again.`,
        )
      })
  }
  const handleRemoveFromGroup = group => {
    resetGroupAddRemoveErrors()

    return removeExecutorReturningPromise(group.id, selectedIds)
      .then(() => {
        onRemovedFromGroup(nameOfThingsToBeAddedRemoved, group.name)
      })
      .catch(() => {
        setIsOnRemoveError(true)
        setAddRemoveErrorMessage(
          `Unable to remove selected ${nameOfThingsToBeAddedRemoved}. Please try again.`,
        )
      })
  }

  const groupList = useGroupList(currentOrganization, groupService, shouldFetchGroups)

  return {
    addRemoveErrorMessage,
    groupList,
    handleAddToGroup,
    handleRemoveFromGroup,
    isOnAddError,
    isOnRemoveError,
    selectedIds,
  }
}

export default useAddRemoveFromGroupButtonState
export const useAddRemoveFromGroupButtonPropType = PropTypes.shape({
  addRemoveErrorMessage: PropTypes.string,
  groupList: PropTypes.arrayOf(groupServicePropType).isRequired,
  handleAddToGroup: PropTypes.func,
  handleRemoveFromGroup: PropTypes.func,
  isOnAddError: PropTypes.bool,
  isOnRemoveError: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
})
