import { useEffect, useState, useRef, useCallback } from 'react'

const useSyncDataWithApi = (
  functionReturningPromise,
  resetTableState,
  setHasApiReturned,
) =>
  useEffect(() => {
    let isMounted = true

    functionReturningPromise()
      .then(values => {
        if (isMounted) {
          resetTableState(values)
          setHasApiReturned(true)
        }
      })
      .catch(() => {
        resetTableState([])
        setHasApiReturned(false)
      })

    return () => {
      // prevents memory leaks and state being updated after unmount
      isMounted = false
    }
  }, [functionReturningPromise, setHasApiReturned, resetTableState])

const useFilterableTableDataAndState = functionReturningPromise => {
  const [tableData, setTableData] = useState([])
  const [hasApiReturned, setHasApiReturned] = useState(false)
  const [doesApiReturnData, setDoesApiReturnData] = useState()

  const unfilteredTableData = useRef([])

  const addItemToTable = newItem => {
    // allows consuming code to update table without hitting server
    const updatedUnfilteredData = [newItem, ...unfilteredTableData.current]

    setTableData(updatedUnfilteredData)
    unfilteredTableData.current = updatedUnfilteredData
  }

  const updateItemInTable = updatedItem => {
    // allows consuming code to update table without hitting server
    const updatedUnfilteredData = unfilteredTableData.current.map(tableItem =>
      tableItem.id === updatedItem.id ? updatedItem : tableItem,
    )

    setTableData(updatedUnfilteredData)
    unfilteredTableData.current = updatedUnfilteredData
  }

  const resetTableState = useCallback(
    apiData => {
      setTableData(apiData)
      unfilteredTableData.current = apiData
      setDoesApiReturnData(!!apiData.length)
    },
    [setTableData, setDoesApiReturnData],
  )

  useSyncDataWithApi(
    functionReturningPromise,
    resetTableState,
    setHasApiReturned,
  )

  return {
    addItemToTable,
    doesApiReturnData,
    hasApiReturned,
    setTableData,
    tableData,
    unfilteredTableData,
    updateItemInTable,
  }
}

export default useFilterableTableDataAndState
