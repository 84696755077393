import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'

import NewUser from '../NewUser'
import OrganizationManager from '../OrganizationManager'
import EditUser from '../EditUser'
import ConfirmAuthTokenReset from '../ConfirmAuthTokenReset'
// import FeedbackForm from '../FeedbackForm'
import NewGroup from '../NewGroup'
import EditGroup from '../EditGroup'
import ResetPassword from '../ResetPassword'

const useAppHandlers = (
  appStateControllers,
  services,
  user,
  currentOrganization,
) => {
  const history = useHistory()
  const [loginError, setLoginError] = useState('')

  const {
    setAuthenticatedState,
    setCurrentOrganization,
    setIsLoggedIn,
    forceRerender,

    setModalPosition,
    openModal,
    handleCloseModal,
  } = appStateControllers
  const {
    groupService,
    userService,
    organizationService,
    authenticationService,
    dataService
  } = services

  const handleLogout = async () => {
    authenticationService.logout()
    setIsLoggedIn(false)
  }

  const handleResetAuthTokenConfirm = () => {
    handleCloseModal()
    handleLogout()
  }

const handleEditUserSave = name => {
    handleCloseModal()
    forceRerender()
    toast.success(`The account, ${name}, has been modified`)
  }
  // const handleFeedbackSent = () => {
  //   handleCloseModal()
  //   toast.success(`Your feedback has been sent`)
  // }

  const handleDeleteUser = userInfo => {
    handleCloseModal()
    forceRerender()
    toast.success(`The account, ${userInfo.name}, has been deleted`)
  }

  const handleCreateNewAccount = ({ username }) => {
    handleCloseModal()
    forceRerender()
    toast.success(`The account, ${username}, has been added`)
  }

  const handleAddUser = (groupList) => {
    openModal(
      <NewUser
        groupService={groupService}
        userService={userService}
        onClose={handleCloseModal}
        onCreateNewAccount={handleCreateNewAccount}
        groupList={groupList}
        currentOrganization={currentOrganization}
      />,
      true
    )
  }

  const handleOrganizationManagerClick = (allowDismissal) => {
    setModalPosition('center')
    openModal(
      <OrganizationManager
        onClose={handleCloseModal}
        organizationService={organizationService}
        onOrganizationSelect={setCurrentOrganization}
        allowDismissal={allowDismissal}
        layerService={dataService}
      />,
      allowDismissal
    )
  }

  const handleEditUser = (id, groupList) => {
    openModal(
      <EditUser
        userService={userService}
        userId={id}
        onSave={handleEditUserSave}
        onDelete={handleDeleteUser}
        onCancel={handleCloseModal}
        groupList={groupList}
        authenticationService={authenticationService}
      />,
      true
    )
  }

  const handleResetAuthToken = () => {
    openModal(
      <ConfirmAuthTokenReset
        userService={userService}
        onCancel={handleCloseModal}
        onConfirm={handleResetAuthTokenConfirm}
      />,
      true
    )
  }

  // const handleFeedbackClick = () => {
  //   setModalPosition('center')

  //   openModal(
  //     <FeedbackForm
  //       onCancel={handleCloseModal}
  //       onSuccess={handleFeedbackSent}
  //       userName={user.name}
  //       userService={userService}
  //     />,
  //     true
  //   )
  // }
  const handleLogin = async value => {
    const { username, password } = value

    authenticationService
      .authenticate(username, password)
      .then(account => {
        setAuthenticatedState(account)
        setLoginError('')
        history.push('/')
      })
      .catch(() => {
        setIsLoggedIn(false)
        setLoginError(
          <>
            Wrong user name or password.
            <br />
            Please try again or contact your admin.
          </>,
        )
      })
  }

  const handleNewPasswordCreated = () => {
    history.push('/')
    toast.success('Your new password has been created')
  }

  const handleCreateGroupClick = () => {
    const handleNewGroupSuccess = newGroup => {
      handleCloseModal()
      forceRerender()
      toast.success(`The group, ${newGroup.name}, has been created`)
    }

    openModal(
      <NewGroup
        onCancel={handleCloseModal}
        currentOrganization={currentOrganization}
        onSuccess={handleNewGroupSuccess}
        groupServiceInstance={groupService}
      />,
      true
    )
  }

  const handleEditGroupClick = group => {
    const handleEditGroupSuccess = editedGroup => {
      handleCloseModal()
      forceRerender()
      toast.success(`The group, ${editedGroup.name}, has been updated`)
    }

    const handleDeleteGroupSuccess = deletedGroup => {
      handleCloseModal()
      forceRerender()
      toast.success(`The group, ${deletedGroup.name}, has been deleted`)
    }

    openModal(
      <EditGroup
        onCancel={handleCloseModal}
        group={group}
        groupServiceInstance={groupService}
        onSaveSuccess={handleEditGroupSuccess}
        onDeleteSuccess={handleDeleteGroupSuccess}
      />,
      true
    )
  }

  const handleAddedToGroup = (nameOfThingsToBeAdded, nameofGroup) => {
    forceRerender()
    toast.success(
      `The selected ${nameOfThingsToBeAdded} have been added to the group, ${nameofGroup}`,
    )
  }

  const handleRemovedFromGroup = (nameOfThingsToBeRemoved, nameofGroup) => {
    forceRerender()
    toast.success(
      `The selected ${nameOfThingsToBeRemoved} have been removed from the group, ${nameofGroup}`,
    )
  }

  const handleOpenPasswordResetForm = () => {
    const handlePasswordReset = values => {
      userService.resetPassword(values)
        .then(() => {
          handleCloseModal()
          forceRerender()
          toast.success("Your password has been reset")
        })
        .catch(() => {
          toast.error("Wrong password, please try again.")
        })
    }

    setModalPosition('center')
    openModal(
      <ResetPassword
        onCancel={handleCloseModal}
        onConfirm={handlePasswordReset}
      />,
      true
    )
  }

  return {
    loginError,
    handleAddedToGroup,
    handleAddUser,
    handleCloseModal,
    handleCreateGroupClick,
    handleEditGroupClick,
    handleEditUser,
    // handleFeedbackClick,
    handleLogin,
    handleLogout,
    handleNewPasswordCreated,
    handleOrganizationManagerClick,
    handleRemovedFromGroup,
    handleResetAuthToken,
    handleOpenPasswordResetForm
  }
}

export default useAppHandlers
