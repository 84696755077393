import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components/macro'

import { TableMessageContainer } from '../../appMiscStyledComponents'

/**
 * Status indicator
 */
const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.designTokens.color.textSecondary};
  animation: spin 5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const StatusIndicator = () => {
  return (
    <TableMessageContainer>
      <CustomFontAwesomeIcon
        icon={faSpinner}
        size="3x"
        aria-label="loading indicator"
      />
    </TableMessageContainer>
  )
}

export default StatusIndicator
